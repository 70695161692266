import { Divider, Tab, Tabs, ThemeProvider } from "@material-ui/core";
import React from "react";
import MuiThemeCustomTabs from "~/components/common/customTabs/MuiThemeCustomTabs";

const CustomTabs = (props) => {
  const { value, handleChange, tabs } = props;

  return (
    <ThemeProvider theme={MuiThemeCustomTabs}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        scrollButtons="auto"
        variant="scrollable"
      >
        {tabs.map((item, index) => (
          <Tab
            key={index}
            value={item.value}
            label={item.label}
            style={{ 
              fontSize: "15px", 
              paddingBottom: "0", 
              width: "32%",
            }}
          />
        ))}
      </Tabs>
    </ThemeProvider>
  );
};

export default CustomTabs;
