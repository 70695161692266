import { Divider } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import timeIcon from "assets/icons/group-4.png";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useStyles, { Accordion, AccordionDetails, AccordionSummary, Container } from "./styles";
import { Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PurpleCheckBox from "~/components/common/purpleCheckBox/purpleChackBox";
import SpinnerLoading from "~/components/common/spinnerLoading/SpinnerLoading";
import Utils from "~/helpers/Utils";
import { useTranslation } from "react-i18next";

const VendorPackages = ({ getSelectedPackage = () => {}, services, clearPackages, packages }) => {

	const classes = useStyles();

	const { business } = useSelector((store) => store.business);
	const { user } = useSelector((store) => store.auth);
	const { me } = useSelector((state) => state.me);
	const meData = JSON.parse(localStorage.getItem("@prit-web/meData"));
	const [selectedPackages, setSelectedPackages] = useState([]);
	const [bookingPayload, setBookingPayload] = useState(null);
	const { t } = useTranslation();

	const handleCheckboxChange = (packageItem) => {
		setSelectedPackages((prevSelected) => {
			const isAlreadySelected = prevSelected.some((selectedPackage) => selectedPackage.id === packageItem.id);

			let updatedSelectedPackages;
			if (isAlreadySelected) {
				updatedSelectedPackages = prevSelected.filter((selectedPackage) => selectedPackage.id !== packageItem.id);
			} else {
				updatedSelectedPackages = [packageItem];
			}

			const newPayload = generateBookingPayload(updatedSelectedPackages);
			setBookingPayload(newPayload);

			return updatedSelectedPackages;
		});
	};

	const generateBookingPayload = (selectedPackagesList) => {
		if (selectedPackagesList.length === 0) {
			getSelectedPackage(null);
			return null;
		}

		let allBookings = [];
		let infoPackage = null;
		let selectedPackageId = null;

		if (selectedPackagesList.length === 1) {
			selectedPackageId = selectedPackagesList[0].id;
		}

		for (const packageItem of selectedPackagesList) {
			const bookings = [];
			const info = {
				serviceName: packageItem.service?.name,
				duration: packageItem.service?.duration,
				packageName: packageItem.name,
				pricingType: packageItem.pricingType,
				quantity: packageItem.quantity,
				packagePrice: packageItem.price
			};
			infoPackage = info;
			for (let i = 0; i < packageItem.quantity; i++) {
				const data = {
					business_id: packageItem.business?.id,
					professional_id: packageItem.professional?.id,
					service_id: packageItem.service?.id,
					pricing_type_id: packageItem.pricingType?.id,
					acesso: "web",
					answer: "",
					price: (packageItem.price / packageItem.quantity),
				};

				bookings.push(data);
			}
			allBookings = [...allBookings, ...bookings];
		}

		const payload = {
			body: {
				bookings: allBookings,
				is_cancellation_policy: false,
				is_offer_refused: false,
				package_id: selectedPackageId,
			},
			info: infoPackage,
		};

		getSelectedPackage(payload);
		return payload;
	};

	useEffect(() => {
		if (clearPackages) {
			setSelectedPackages([]);
		}
	}, [clearPackages]);

	return (
		<Container>
			{packages.length > 0 ? (
				<>
					<Typography className={classes.levelTitle} style={{ marginBottom: "10px" }}>{t("Service Packages")}</Typography>
					{packages.map((item) => (
						<Accordion key={item.id} onChange={() => handleCheckboxChange(item)}>
							<AccordionSummary
								expandIcon={<ArrowDropDownIcon />}
								aria-controls={`panel-${item.id}-content`}
								id={`panel-${item.id}-header`}>
								<div className={classes.row}>
									<PurpleCheckBox
										checkedIcon={<CheckCircleIcon />}
										checked={selectedPackages.some((selectedPackage) => selectedPackage.id === item.id)}
									/>
									<Typography className={classes.title}>{item.name}</Typography>
									<div className={classes.priceDiv}>
										<Typography className={classes.price}>R$ {Utils.moneyMask(item.price / 100)}</Typography>
									</div>
								</div>
							</AccordionSummary>
							<AccordionDetails>
								<Typography className={classes.moreFeatureTitle}>{t("Services included in the package")}:</Typography>
								<div className={classes.featuresInformationDiv}>
									<img src={timeIcon} alt="Ícone de tempo" className={classes.icon} />
									<Typography translate="no" className={classes.name}>
										{item.quantity} {item.service?.name}
									</Typography>
									<Typography translate="no" className={classes.time}>{item.service?.duration}m</Typography>
								</div>
								{item.includedServices && (
									<div>
										<Typography className={classes.moreFeatureTitle}>Serviços Inclusos (Detalhado):</Typography>
										{item.includedServices.map((service, serviceIndex) => (
											<div key={serviceIndex} className={classes.featuresInformationDiv}>
												<img src={service.icon || timeIcon} alt="Ícone do Serviço" className={classes.icon} />
												<Typography className={classes.name}>{service.name}</Typography>
												<Typography className={classes.time}>{service.duration}min</Typography>
											</div>
										))}
									</div>
								)}
							</AccordionDetails>
						</Accordion>
					))}
				</>
			) : (
				<SpinnerLoading />
			)}
		</Container>
	);
};

export default VendorPackages;
