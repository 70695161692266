import mixpanel from "mixpanel-browser";
import _ from 'lodash';
import { postEvent } from "~/services/mixpanelServices";

const mixpanelTokenOld = process.env.REACT_APP_MIXPANEL_TOKEN;
const mixpanelTokenNew = process.env.REACT_APP_MIXPANEL_TOKEN_NEW;

export const mixpanelInitialize = () => {
  try {
    mixpanel.init(mixpanelTokenOld, { debug: true }, 'old');
    mixpanel.init(mixpanelTokenNew, { debug: true }, 'new');
  } catch (err) {
    console.log(err);
  }
};

const  getOS = () => {
  const userAgent = navigator.userAgent;
  if (/Windows NT/.test(userAgent)) {
    return "windows";
  } else if (/Mac OS X|Macintosh/.test(userAgent)) {
    return "ios";
  } else if (/Android/.test(userAgent)) {
    return "android";
  } else if (/Linux/.test(userAgent)) {
    return "linux";
  } else if (/iPhone|iPad/.test(userAgent)) {
    return "ios";
  } else if (/CrOS/.test(userAgent)) {
    return "chrome";
  }
  return "Sistema operacional desconhecido";
}

export const mixpanelUserInitialize = (user = {}, event = '', sendApi = false) => {
  if (!_.isEmpty(user)) {
    try {
      mixpanel.old.identify(user?.phone || user?.phoneNumber || user?.phoneUnverified || user.uid || '');
      mixpanel.old.people.unset("$So");
      mixpanel.old.people.unset("$SO");
      mixpanel.old.people.unset("$so");
      mixpanel.old.people.unset("So");
      mixpanel.old.people.unset("so");
      mixpanel.old.people.set({
        "$name": user?.name || 'Sem nome',
        "$email": user?.email || 'Sem email',
        "$phone": user?.phone || user?.phoneNumber || user?.phoneUnverified || 'Sem telefone',
        "SO": getOS(),
        "$Último acesso": new Date().toString(),
      });
      mixpanel.old.register({
        "$name": user?.name || 'Sem nome',
        "$email": user?.email || 'Sem email',
        "$phone": user?.phone || user?.phoneNumber || user?.phoneUnverified || 'Sem telefone',
        "SO": getOS(),
        "$Último acesso": new Date().toString(),
      });
      mixpanel.old.track(`${event} - Web Consumidor`);
      if (user?.id) {
        mixpanel.new.identify(`u-${user?.id || ''}`);
        mixpanel.new.people.unset("$So");
        mixpanel.new.people.unset("$SO");
        mixpanel.new.people.unset("$so");
        mixpanel.new.people.unset("So");
        mixpanel.new.people.unset("so");
        mixpanel.new.people.set({
          "$name": user?.name || 'Sem nome',
          "$email": user?.email || 'Sem email',
          "$phone": user?.phone || user?.phoneNumber || user?.phoneUnverified || 'Sem telefone',
          "$client": true,
          "$userEntity": true,
          "SO": getOS(),
          "$Último acesso": new Date().toString(),
        });
        mixpanel.new.register({
          "$name": user?.name || 'Sem nome',
          "$email": user?.email || 'Sem email',
          "$phone": user?.phone || user?.phoneNumber || user?.phoneUnverified || 'Sem telefone',
          "$client": true,
          "$userEntity": true,
          "SO": getOS(),
          "$Último acesso": new Date().toString(),
        });
        mixpanel.new.track(`${event} - Web Consumidor`);
        if (sendApi) {
          postEvent({message: `${event} - Web Consumidor`, business_id: '', user_id: user?.id || ''});
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
};

export const mixpanelTrackEvent = (event = '', sendUser = {}, sendBusiness = {}, sendApi = false) => {
  try {
    if (!_.isEmpty(sendBusiness)) {
      mixpanel.old.identify(sendBusiness?.phone || '');
      mixpanel.old.track(`${event} - Web Consumidor`);

      mixpanel.new.identify(`b-${sendBusiness?.id || ''}`);
      mixpanel.new.track(`${event} - Web Consumidor`);
    }
    if (!_.isEmpty( sendUser)) {
      mixpanel.old.identify(sendUser?.phone || sendUser?.phoneNumber || sendUser?.phoneUnverified || '');
      mixpanel.old.track(`${event} - Web Consumidor`);

      mixpanel.new.identify(`u-${sendUser?.id || ''}`);
      mixpanel.new.track(`${event} - Web Consumidor`);
    }
    if (_.isEmpty(sendUser) && _.isEmpty(sendBusiness)) {

      mixpanel.old.track(`${event} - Web Consumidor`);
      mixpanel.new.track(`${event} - Web Consumidor`);
    }
    if (sendApi) {
      postEvent({message: `${event} - Web Consumidor`, business_id: sendBusiness?.id || '', user_id: sendUser?.id || ''});
    }
  } catch (err) {
    console.log(err);
  }
};