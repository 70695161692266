import dayjs from "~/config/dayjs";
import api from "~/services/api";

const CONTROLLER_NAME = "/search";

const INITIAL_PARAMS = {
	start_time: dayjs().startOf("day").utc().format(),
	end_time: dayjs().endOf("day").utc().format(),
	business_id: "",
	services: [],
	per_page: null,
	page: null,
	latitude: null,
	longitude: null,
	language: null,
};

export const postSearch = (params = INITIAL_PARAMS) => {
	return api.post(CONTROLLER_NAME, { ...params });
};

export default { postSearch };
