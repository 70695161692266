import { Button, DialogTitle, Divider, Typography } from "@material-ui/core";
import React from "react";
import TitleDialog from "~/components/modalBookingScreen/TitleDialog";

import useStyles from "./style";
import { withRouter } from "react-router";

import _ from "lodash";

const DialogDiscount = (props) => {
	const { onClose, checkoutInformation, currency, acceptOffer, refuseOffer } = props;
	const classes = useStyles();

	return (
		<>
			<DialogTitle>{<TitleDialog onClose={onClose} message={"Desconto pagamento antecipado"} />}</DialogTitle>
			<div className={classes.container}>
				<div>
					<div style={{ padding: 20 }}>
						<Typography className={classes.message} style={{ textAlign: "center" }}>
							{"Pague antecipado sem reembolso e ganhe " +
								checkoutInformation?.anticipation_payment_percentage +
								"% de desconto."}
						</Typography>
						<Divider />
						<div style={{ padding: 5 }}>
							<Typography className={classes.information}>Total dos serviços</Typography>
							<Typography className={classes.value}>
								{parseFloat(checkoutInformation?.total_value_for_services / 100).toLocaleString("pt-BR", {
									style: "currency",
									currency: currency,
								})}
							</Typography>
							<Typography className={classes.information}>Desconto</Typography>
							<Typography className={classes.value}>
								{parseFloat(checkoutInformation?.total_discounts / 100).toLocaleString("pt-BR", {
									style: "currency",
									currency: currency,
								})}
							</Typography>
						</div>
						<Divider />
						<div style={{ padding: 5 }}>
							<Typography style={{ color: "#3689ea" }} className={classes.information}>
								Saldo a pagar
							</Typography>
							<Typography style={{ color: "#3689ea", fontWeight: "bold" }} className={classes.value}>
								{parseFloat(checkoutInformation?.anticipation_payment / 100).toLocaleString("pt-BR", {
									style: "currency",
									currency: currency,
								})}
							</Typography>
						</div>
						<Divider />
					</div>
				</div>
			</div>
			<div style={{ textAlign: "center" }}>
				<Button
					onClick={() => {
						acceptOffer();
						onClose();
					}}
					className={classes.root}>
					Pagar antecipado
				</Button>
				<Button
					onClick={() => {
						refuseOffer({is_offer_refused: true});
						onClose();
					}}
					className={classes.root}>
					Recusar oferta
				</Button>
			</div>
		</>
	);
};

export default withRouter(DialogDiscount);
