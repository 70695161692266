export const returnLanguage = (lang = "portuguese") => {
	switch (lang) {
		case "portuguese":
			return "Port";
		case "english":
			return "Eng";
		case "spanish":
			return "Esp";
		default:
			return "";
	}
};

export const treatCategoriesLanguage = (lang = "Port") => {
	if (lang === "Port") return "";
	return lang;
};
