import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import ErrorDialog from "~/components/common/dialog";
import dayjs from "~/config/dayjs";
import searchService from "~/services/searchService";
import couponServices from "~/services/couponServices";
import MuiThemeSelectScheduleTime from "./MuiStylesSelectScheduleTime";
import FeaturesSelected from "~/components/common/featuresSelected/FeaturesSelected";
import { Typography } from "@material-ui/core";
import ProfessionalDiv from "~/components/common/professionalDiv/ProfessionalDiv";
import BusinessDiv from "~/components/common/businessDiv/BusinessDiv";
import TitleContainer from "~/components/common/titleContainer/TitleContainer";
import { useTranslation } from "react-i18next";
import ScheduleProgress from "~/components/common/scheduleProgress/ScheduleProgress";
import TimeOptionsDiv from "~/components/common/TimeOptionsDiv";
import { useContext } from "react";
import { DialogBuilder } from "~/utils/DialogProvider";
import ColorButton from "~/components/common/colorButton/ColorButton";
import SEO from "~/components/seo";
import WhatsAppButton from "~/components/common/whatsappButton/whatsappButton";
import _ from "lodash";
import camelizeKeys from "../../utils/camelizeKeys";

const SelectScheduleTime = (props) => {
	const bookingId = props?.location?.state?.bookingId;
	const reservationUpdate = props?.location?.state?.reservationUpdate;
	const feature_items = props?.location?.state?.feature_items;
	const coupon = props?.location?.state?.coupon;
	const reschedule = props?.location?.state?.reschedule;
	const date = props?.location?.state?.date;
	const selectedServices = props?.location?.state?.services;
	const dialog = useContext(DialogBuilder);
	const classes = MuiThemeSelectScheduleTime();
	const { user } = useSelector((store) => store.auth);
	const { business } = useSelector((store) => store.business);
	const { t } = useTranslation();

	const { id } = useParams();
	const [message, setMessage] = useState(t("The establishments are closed for this day and time"));
	const [open, setOpen] = useState(false);
	const [showFinishButton, setShowFinishButton] = useState(false);
	const [loading, setLoading] = useState(false);
	const [currentServiceIndex, setCurrentServiceIndex] = useState(0);
	const [servicesScheduled, setServicesScheduled] = useState([]);
	const [serviceTimeOptions, setServiceTimeOptions] = useState([]);
	const [unavailability, setUnavailability] = useState([]);
	const [professionalIndexFirst, setProfessionalIndexFirst] = useState(0);
	const [listProfessionals, setListProfessionals] = useState([]);
	const [alterProfessional, setAlterProfessional] = useState(null);

	const isPackage = props?.location?.state?.isPackage;
	const [priceServicePackage, setPriceServicePackage] = useState(0);

	const initialize = (data, hasUnavailabilities = false) => {
		let professionals = [];
		for (let i = 0; i < data.services.length; i++) {
			for (let j = 0; j < data.services[i].professionals.length; j++) {
				if (professionals.findIndex((e) => e.id === data.services[i].professionals[j].id) === -1) {
					professionals.push(data.services[i].professionals[j]);
				}
			}
		}
		
		setListProfessionals(professionals);

		if (hasUnavailabilities) {
			setUnavailability(professionals);
		}

		let newServiceTimeOptions = data;
		let professionalIndexFirst = -1;
		const combinedSchedule = [];

		if (!business?.allowSelectProfessional) {
			newServiceTimeOptions.services.forEach((service) => {
				if (service.professionals.length > 0) {
					const professionalAvailability = new Map();

					service.professionals.forEach((professional) => {
						professionalAvailability.set(professional.id, []);
					});

					service.professionals.forEach((professional) => {
						professional.schedule.forEach((scheduleSlot) => {
							const availability = scheduleSlot[0];
							professionalAvailability.get(professional.id).push(availability);
						});
					});

					professionalAvailability.forEach((availabilities) => {
						availabilities.forEach((availability) => {
							if (!combinedSchedule.some((s) => s.start === availability.start)) {
								combinedSchedule.push({ start: availability.start, end: availability.end });
							}
						});
					});
					
					combinedSchedule.sort((a, b) => new Date(a.start) - new Date(b.start));
					let firstProfessional = { ...service.professionals[0] }; 
					firstProfessional.unavailability = [];
					service.professionals = [firstProfessional];
				}
			});
		}

		newServiceTimeOptions.services.forEach((service, serviceIndex) => {
			service.professionals.forEach((professional, professionalIndex) => {
				let unavailableSlots = professional?.unavailability ?? [];
				let newSlots = [];
				let timeLapse =
					newServiceTimeOptions.business.timeLapse > 0
						? newServiceTimeOptions.business.timeLapse
						: professional.duration;
				for (let k = 0; k < professional.availability.length; k++) {
					if (new Date(professional.availability[k].start) < new Date()) {
						continue;
					}
					let currentTime = new Date(professional.availability[k].start);
					let finalTime = new Date(professional.availability[k].end);
					while (currentTime < finalTime) {
						let endTime = new Date(currentTime.getTime() + professional.duration * 60 * 1000);
						let collisions = 0;
						let nearCollisionEnd;
						for (let j = 0; j < unavailableSlots.length; j++) {
							let start = new Date(unavailableSlots[j].start);
							let end = new Date(unavailableSlots[j].end);
							if (!(end <= currentTime || start >= endTime)) {
								if (!nearCollisionEnd || end < nearCollisionEnd) {
									nearCollisionEnd = end;
								}
								collisions++;
							}
						}
						if (collisions < professional.vacancies) {
							if (endTime <= finalTime) {
								if (professionalIndexFirst == -1) {
									professionalIndexFirst = professionalIndex;
								}
								newSlots.push([
									{
										start: currentTime.toUTCString(),
										end: endTime.toUTCString(),
									},
									professional.vacancies,
								]);
							}
							currentTime = new Date(currentTime.getTime() + timeLapse * 60 * 1000);
						} else {
							currentTime = nearCollisionEnd;
						}
					}
					newServiceTimeOptions.services[serviceIndex].professionals[professionalIndex].schedule =
						combinedSchedule && combinedSchedule.length > 0
							? combinedSchedule.map((slot) => [
									{
										start: new Date(slot.start).toUTCString(),
										end: new Date(slot.end).toUTCString(),
									},
									newServiceTimeOptions.services[serviceIndex].professionals[professionalIndex].vacancies, 
							  ])
							: newSlots;
				}
			});
		});
		setServiceTimeOptions(newServiceTimeOptions);
		let service = newServiceTimeOptions?.services.find((e) => e?.id === selectedServices[0]?.id);
		
		if (isPackage) {
			service.price = selectedServices[0].price;
			setPriceServicePackage(service.price);
		}

		setProfessionalIndexFirst(professionalIndexFirst);
		selectTime(
			{
				time: service.professionals[professionalIndexFirst].schedule[0][0],
				professional: service.professionals[professionalIndexFirst],
				service: service,
			},
			0,
			newServiceTimeOptions,
			professionals,
		);
	};

	useEffect(() => {
		let dates = {
			start_time: dayjs(date).startOf("day").utc().format(),
			end_time: dayjs(date).endOf("day").utc().format(),
		};
		let featureItems;
		if (selectedServices) {
			featureItems = selectedServices.map((service) => {
				return service.featureItems;
			});
		}

		setLoading(true);
		if (coupon) {
			couponServices
				.postSearchCoupon({
					...dates,
					business_id: business.id,
					services: featureItems,
					coupon_id: coupon?.coupon.coupon_id,
				})
				.then(({ data }) => {
					if (data.length === 0) {
						setOpen(true);
					}
					var newData = camelizeKeys(data[0]);
					initialize(newData);
					setLoading(false);
				})
				.catch((e) => {
					dialog
						.withTitle(t("The services are unavailable at the moment."))
						.withAction(() => props.history.goBack())
						.show();
					setLoading(false);
				});
		} else {
			searchService
				.postSearch({ ...dates, business_id: business.id, services: featureItems, language: business.language })
				.then(({ data }) => {
					if (data.length === 0) {
						setOpen(true);
						return;
					}
					if (data[0].services.length < selectedServices.length) {
						dialog
							.withTitle(t("There are no professionals available for these services at the moment"))
							.withAction(() => props.history.goBack())
							.show();
						return;
					}
					initialize(data[0], true);
					setLoading(false);
				})
				.catch((e) => {
					dialog
						.withTitle(t("The services are unavailable at the moment."))
						.withAction(() => props.history.goBack())
						.show();
					setLoading(false);
				});
		}
	}, []);

	const getUnavailableSlots = (servicesScheduled, currentServiceId, currentProfessionalId, allUnavailabilities) => {
		let unavailableSlots = [];

		let professionalUnavailability = allUnavailabilities.find((u) => u.id === currentProfessionalId);
		if (professionalUnavailability) {
			unavailableSlots.push(...professionalUnavailability.unavailability);
		}

		servicesScheduled.forEach((selectedSchedule) => {
			if (selectedSchedule && selectedSchedule.service.id !== currentServiceId) {
				if (selectedSchedule.professional.id === currentProfessionalId) {
					unavailableSlots.push(selectedSchedule.time);
				}
			}
		});

		return [...new Set(unavailableSlots)];
	};

	function findNextAvailableProfessional(selectedTime, service) {
		if (!service) {
		  return null; 
		}
	  
		for (let i = 0; i < service.length; i++) {
		  const professional = service[i];
		  if (professional.schedule && Array.isArray(professional.schedule)) {
			for (let j = 0; j < professional.schedule.length; j++) {
			  const slot = professional.schedule[j];
	  
			  if (Array.isArray(slot) && slot.length > 0) {
				const timeSlot = slot[0]; 
	  
				if (timeSlot && timeSlot.start && timeSlot.end) {
				  if (
					new Date(timeSlot.start).getTime() === new Date(selectedTime.start).getTime() &&
					new Date(timeSlot.end).getTime() === new Date(selectedTime.end).getTime()
				  ) {
					return professional;
				  }
				}
			  }
			}
		  }
		}
		return null;
	  }

	const selectTime = (schedule, currentService, options, unavailabilities) => {
		if (!business.allowSelectProfessional) {
			const nextProfessional = findNextAvailableProfessional(schedule.time, unavailabilities ?? listProfessionals) ?? null;
			setAlterProfessional(nextProfessional);
		}
		let services = [...(servicesScheduled || [])];

		// Marcar/Desmarcar o horário.
		if (
			services[currentService ?? currentServiceIndex] &&
			services[currentService ?? currentServiceIndex].time.start === schedule.time.start
		) {
			services[currentService ?? currentServiceIndex] = null;
		} else {
			services[currentService ?? currentServiceIndex] = schedule; 
		}
		setServicesScheduled(services);
		
		let newServiceTimeOptions = options ? _.cloneDeep(options) : _.cloneDeep(serviceTimeOptions);
		let newUnavailbility = unavailabilities ? _.cloneDeep(unavailabilities) : _.cloneDeep(unavailability);
		
		let allTimesDefined = services.every(service => service); //Verifica se todos os serviços foram selecionados
		if (allTimesDefined) {
			setShowFinishButton(true);
		}

		newServiceTimeOptions.services.forEach((service, i) => {
			if (service.id !== schedule.service.id) {
				let professionalIndex = newUnavailbility.findIndex((e) => e.id === schedule.professional.id);
				if (professionalIndex === -1) {
					return;
				}
				let professional = newUnavailbility[professionalIndex];
				let newSlots = [];
				let timeLapse =
					newServiceTimeOptions.business.timeLapse > 0
						? newServiceTimeOptions.business.timeLapse
						: professional.duration;

				let currentUnavailableSlots = getUnavailableSlots(services, service.id, professional.id, newUnavailbility);

				for (let k = 0; k < professional.availability.length; k++) {
					let currentTime = new Date(professional.availability[k].start);
					let finalTime = new Date(professional.availability[k].end);
					while (currentTime < finalTime) {
						let endTime = new Date(currentTime.getTime() + professional.duration * 60 * 1000);
						let collisions = 0;
						let nearCollisionEnd;
						for (let j = 0; j < currentUnavailableSlots.length; j++) {
							// Usa currentUnavailableSlots
							let start = new Date(currentUnavailableSlots[j].start);
							let end = new Date(currentUnavailableSlots[j].end);
							if (!(end <= currentTime || start >= endTime)) {
								if (!nearCollisionEnd || end < nearCollisionEnd) {
									nearCollisionEnd = end;
								}
								collisions++;
							}
						}
						if (collisions < professional.vacancies) {
							if (endTime <= finalTime) {
								newSlots.push([
									{
										start: currentTime.toUTCString(),
										end: endTime.toUTCString(),
									},
									professional.vacancies,
								]);
							}
							currentTime = new Date(currentTime.getTime() + timeLapse * 60 * 1000);
						} else {
							currentTime = nearCollisionEnd;
						}
					}
				}
				newServiceTimeOptions.services[i].professionals[!business.allowSelectProfessional ? 0 : professionalIndex].schedule = newSlots;
			}
		});
		setServiceTimeOptions(newServiceTimeOptions);
	};
	const goToNextPage = (services) => {
		for (let i = 0; i < services.length; i++) {
			if (!services[i]) {
				setMessage(
					t("You forgot to select the time for the") +
						(i + 1) +
						t("service, please set a time for it before continuing")
				);
				setOpen(true);
				return;
			}
		}
		props.history.push(`/checkout/${id}`, {
				recipientId: id,
				questions: serviceTimeOptions.questions,
				services: services.map((schedule, i) => {
					return {
						//questions: [],
						date: schedule.time.start,
						service_id: schedule.service.id,
						professional_id: alterProfessional ? alterProfessional.id : schedule.professional.id,
						professional: {
							duration: alterProfessional ? alterProfessional.duration : schedule.professional.duration,
							id: alterProfessional ? alterProfessional.id : schedule.professional.id,
							name: alterProfessional ? alterProfessional.name : schedule.professional.name,
							price: alterProfessional ? alterProfessional.price : schedule.professional.price,
							pricingType: alterProfessional ? alterProfessional.pricingType : schedule.professional.pricingType,
							avatarUrl: alterProfessional ? alterProfessional.avatarUrl : schedule.professional.avatarUrl,
						},
						service: {
							serviceDuration: alterProfessional ? alterProfessional.duration : schedule.professional.duration,
							serviceName: schedule.service.name ?? schedule.service.serviceName,
							servicePrice: schedule.service.price,
						},
						dateStart: schedule.time.start,
					};
				}),
				bookingId: bookingId,
				reservationUpdate: reservationUpdate,
				verification: true,
				feature_items: feature_items,
				coupon: coupon,
				reschedule: reschedule,
				schedulingServicePackage: isPackage ?? false
			});
	};

	const handleOk = () => {
		setOpen(!open);
		setLoading(false);
	};

	const footer = () => {
		return (
			<div style={{ display: "flex", justifyContent: "space-between", margin: "20px" }}>
				{date && (
					<div style={{ display: "flex" }}>
						<div style={{ backgroundColor: "#450067" }} className={classes.countSelected}>
							{selectedServices.length}
						</div>
						<div className={classes.nameCountServices}>{t("Services time")}</div>
					</div>
				)}
				<ColorButton
					translate="no"
					variant="contained"
					style={{
						backgroundColor: "#450067",
						color: "white",
					}}
					className={classes.nameButton}
					onClick={() => {
						if (currentServiceIndex === selectedServices.length - 1) {
							let services = [
								{
									...servicesScheduled[0],
									service: !isPackage ? { ...servicesScheduled[0].service, price: servicesScheduled[0].professional.price } : { ...servicesScheduled[0].service },
								},
								...servicesScheduled.slice(1),
							];
							goToNextPage(services);
						} else {
							setCurrentServiceIndex(currentServiceIndex + 1);
							if (!servicesScheduled[currentServiceIndex + 1]) {
								let service = serviceTimeOptions?.services.find(
									(e) => e?.id === selectedServices[currentServiceIndex + 1]?.id
								);
								service = { ...service, price: service.professionals[professionalIndexFirst].price };
								let schedule = !isPackage ? service.professionals[professionalIndexFirst].schedule : { ...servicesScheduled[0].service };
								if (!_.isEmpty(schedule) && !_.isEmpty(schedule[0])) {
									selectTime(
										{
											time: schedule[0][0],
											professional: service.professionals[0],
											service: service,
										},
										currentServiceIndex + 1
									);
								}
							}
						}
					}}>
					{t("Next")}
				</ColorButton>
			</div>
		);
	};
	return (
		<>
			<TitleContainer
				title={<Typography className={classes.selectTimeLabel}>{t("Select Time")}</Typography>}
				handleClick={() => props.history.goBack()}
				footer={footer()}
				loading={loading}>
				<ScheduleProgress progress={3}></ScheduleProgress>
				<SEO business={business} page="Horários" />
				<div className={classes.scrollSelectedArea}>
					{serviceTimeOptions?.services?.length > 0 ? (
						<FeaturesSelected
							features={selectedServices}
							currentIndex={currentServiceIndex}
							setCurrentIndex={(index) => {
								setCurrentServiceIndex(index);
							}}
						/>
					) : (
						!loading && <Typography className={classes.noneServices}>Você removeu todos os serviços</Typography>
					)}
				</div>
				{business?.allowSelectProfessional && serviceTimeOptions?.services?.length > 0 && date ? (
					serviceTimeOptions?.services
						.filter((e) => e.id === selectedServices[currentServiceIndex].id)[0]
						?.professionals.map((professional, index) => {
							return (
								<div style={{ padding: "10px 0" }}>
									<ProfessionalDiv professional={professional} key={`professional-${index}`} professionalView isPackage={isPackage} priceServicePackage={priceServicePackage}/>
									<TimeOptionsDiv
										options={professional.schedule}
										onSelect={selectTime}
										professional={professional}
										professionalIndex={index}
										selected={servicesScheduled.find(
											(e) => e?.service?.id === selectedServices[currentServiceIndex]?.id
										)}
										service={serviceTimeOptions?.services.find(
											(e) => e?.id === selectedServices[currentServiceIndex]?.id
										)}
										timeZone={business.timeZone}
									/>
								</div>
							);
						})
				) : (
					<>
						{serviceTimeOptions?.services?.length > 0 && (
							<>
								<BusinessDiv service={serviceTimeOptions} selectedService={currentServiceIndex} />
								<TimeOptionsDiv
									onSelect={selectTime}
									selected={servicesScheduled.find((e) => e?.service?.id === selectedServices[currentServiceIndex]?.id)}
									service={selectedServices[currentServiceIndex]}
									timeZone={business.timeZone}
									professionals={
										serviceTimeOptions?.services.filter((e) => e.id === selectedServices[currentServiceIndex].id)[0]
											?.professionals
									}
								/>
							</>
						)}
					</>
				)}

				<ErrorDialog onOpen={open} message={message} onOk={handleOk} title={t("Attention")} />
			</TitleContainer>
			<WhatsAppButton bottom={"80px"} />
		</>
	);
};
export default withRouter(SelectScheduleTime);
