import MuiContainer from "@material-ui/core/Container";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { makeStyles, withStyles } from '@material-ui/core/styles';

// Atualizando os estilos
export default makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    display: "table-cell",
		maxHeight: "40px",
		marginLeft: "12px",
		fontFamily: "CircularStd",
		fontSize: "13px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		letterSpacing: "-0.47px",
		color: "#00000091",
		textAlign: "left",
  },
  priceDiv: {
    marginLeft: 'auto',
  },
  price: {
    display: "table-cell",
		maxHeight: "40px",
		marginLeft: "12px",
		fontFamily: "CircularStd",
		fontSize: "13px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		letterSpacing: "-0.47px",
		color: "#00000091",
		textAlign: "left",
  },
  moreFeatureTitle: {
    fontSize: 14,
    color: '#474747',
    fontWeight: 600,
    marginBottom: 10,
  },
  featuresInformationDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    fontSize: 14,
    color: '#474747',
    marginLeft: 10,
  },
  time: {
    fontSize: 12,
    color: '#6c757d',
    marginLeft: 10,
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 5,
  },
}));

export const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    background: '#fff',
    width: '100%',
    padding: 0,
    marginBottom: 5,
  },
  expanded: {
    margin: '5px 0 !important',
  },
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#fff',
    padding: 0,
    height: 40,
    minHeight: 'none',
    height: 'auto',
    margin: 0,
    alignItems:'center',
  },
  content: {
    margin: 0,
  },
  expanded: {
    minHeight: 'none !important',
  }
})(MuiAccordionSummary);

export const AccordionDetails = withStyles({
  root: {
    flexDirection: 'column',
    padding: 0,
    paddingBottom: 10,
    borderBottom: '1px solid #e0e0e0',
  },
})(MuiAccordionDetails);

export const Container = withStyles({
  root: {
    background: '#fff',
    overflow: 'auto',
    "&::-webkit-scrollbar": {
      width: 0,
    },
    width: '100%',
    padding: 0,
    alignItems: 'center',
    height: '20%',
  },
})(MuiContainer); 