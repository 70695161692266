import api from "~/services/api";

const CONTROLLER_NAME = "/business";
const BOOKING_CONTROLLER_NAME = "/booking_transaction";

export const packagesList = (businessId) => {
    return api.get(`${CONTROLLER_NAME}/${businessId}/packages`);
}

export const addSchedulingPackage = (categoryId, body) => {
    return api.post(`${BOOKING_CONTROLLER_NAME}?language=portuguese&category_id=${categoryId}`, body);
}

export const packageBookings = (businessId, page, perPage) => {
    return api.get(`${CONTROLLER_NAME}/${businessId}/packages/bookings?page=${page}&per_page=${perPage}`);
}

export default {
	packagesList,
    addSchedulingPackage,
    packageBookings
};
