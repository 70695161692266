import api from "~/services/api";

export const getAnticipatedAmountCheckout = (data) => {
	return api.post(`${process.env.REACT_APP_URL_API_PAYOUT}payment/anticipated-amount-checkout`, data);
};
export const fetchPaymentAnticipatedAmountByBookTransaction = (data) => {
	return api.post(`${process.env.REACT_APP_URL_API_PAYOUT}payment/anticipated-amount`, data);
};
export const fetchCancellation = (data) => {
	return api.post(`${process.env.REACT_APP_URL_API_PAYOUT}payment/cancellation/values`, data);
};
export const fetchPaymentRequest = (id) => {
	return api.get(`${process.env.REACT_APP_URL_API_PAYOUT}payment/request/client/${id}`);
};
export const me = () => {
	return api.get(`/me`);
};
export const fetchClientByBusiness = (businessId) => {
	return api.get(`/client?business_id=${businessId}&is_current_user=true`);
};
export const fetchCancellationPolicyScreen = (businessId) => {
	return api.get(`${process.env.REACT_APP_URL_API_PAYOUT}recipient/cancellation-policy-screen/${businessId}`);
};
export const fetchCancellationPolicyClient = (businessId, clientId) => {
	return api.get(`${process.env.REACT_APP_URL_API_PAYOUT}recipient/${businessId}/cancellation-policy-client/${clientId}`);
};

export default {
	getAnticipatedAmountCheckout,
	fetchPaymentAnticipatedAmountByBookTransaction,
	fetchCancellation,
	fetchPaymentRequest,
	fetchClientByBusiness,
	fetchCancellationPolicyClient,
	fetchCancellationPolicyScreen,
	me
};
