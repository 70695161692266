import Constants from "~/helpers/enums/Constants";
import meServices from "~/services/meServices";

export const setMe = () => (dispatch) => {
	meServices.getMe()
	  .then(({ data }) => {
		dispatch({ type: Constants.ME, payload: { me: data } });
	  })
	  .catch((error) => {
		alert("Ocorreu um erro no ME " + error.message);
	  });
		
};
export default { setMe };
