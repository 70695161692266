import { InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const languageOptions = [
	{
		name: "Português",
		value: "ptBR",
		flag: "pt",
	},
	{
		name: "English",
		value: "en",
		flag: "us",
	},
	{
		name: "Español",
		value: "esES",
		flag: "es",
	},
];

export const LanguageSwitcher = () => {
	const { i18n, t } = useTranslation();

	const { business } = useSelector((store) => store.business);

	const [language, setLanguage] = useState("");

	const handleChange = (event) => {
		setLanguage(i18n.changeLanguage(event.target.value));
		switch (event.target.value) {
			case "en":
				localStorage.setItem("@prit-web/language", "english");
				setLanguage("en");
				break;
			case "esES":
				localStorage.setItem("@prit-web/language", "spanish");
				setLanguage("esES");
				break;
			default:
				localStorage.setItem("@prit-web/language", "portuguese");
				setLanguage("ptBR");
		}
	};

	useEffect(() => {
		if (business && business?.language) {
			switch (business?.language) {
				case "english":
					i18n.changeLanguage("en");
					setLanguage("en");
					break;
				case "spanish":
					i18n.changeLanguage("esES");
					setLanguage("esES");
					break;
				default:
					i18n.changeLanguage("ptBR");
					setLanguage("ptBR");
			}
		}
	}, [business, i18n]);

	return (
		<div style={{ textAlign: "right", display: "flex", justifyContent: "flex-end", marginTop: "-1%" }}>
			<div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
				<InputLabel id="demo-simple-select-standard-label" style={{ marginRight: "8px", fontSize: "12px" }}>
					{t("Language")}:
				</InputLabel>
				<Select
					style={{ paddingRight: "35px", fontSize: "12px" }}
					labelId="demo-simple-select-standard-label"
					id="demo-simple-select-standard"
					defaultValue={business?.language === "portuguese" ? "ptBR" : business?.language === "spanish" ? "esES" : "en"}
					value={language}
					onChange={handleChange}
					label="Age"
					disableUnderline>
					{languageOptions.map((languageOptions) => (
						<MenuItem
							style={{ textDecoration: "none", marginRight: "8px", fontSize: "12px" }}
							value={languageOptions.value}>
							{languageOptions.name}
						</MenuItem>
					))}
				</Select>
			</div>
		</div>
	);
};
