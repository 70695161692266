import { Checkbox } from "@material-ui/core";
import React from "react";
import { withStyles } from "@material-ui/core";

const PurpleCheckBox = withStyles({
    root: {
        color: "#ffffff",
        padding: 4,
        "&$checked": {
            color: "#450067",
        },
    },
    checked: {},
})((props) => <Checkbox {...props} />);

export default PurpleCheckBox;
