import { Button, Divider, Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import React from "react";
import { withRouter } from "react-router-dom";
import MuiStylesFooterContainer from "./MuiStylesFooterContainer";
import { AgendaIcon } from "~/components/common/icons/Icons";
import { Gift } from "~/components/common/icons/Icons";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { Fragment } from "react";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import ConfirmationNumberOutlinedIcon from "@material-ui/icons/ConfirmationNumberOutlined";
import { useTranslation } from "react-i18next";
import { Home, HomeOutlined } from "@material-ui/icons";

const FooterContainer = (props) => {
	const classes = MuiStylesFooterContainer();
	const { cancellationPolicy } = useSelector((store) => store.cancellation);
	const { business } = useSelector((store) => store.business);
	const { coupons } = useSelector((store) => store.coupons);
	const { user } = useSelector((store) => store.auth);

	const { t } = useTranslation();

	const sectors = JSON.parse(localStorage.getItem("@prit-web/sectors"));

	const handleScheduleReservationsClick = () => {
		if (user && business && (user.isAnonymous || !user.uid)) {
			props.history.push(`/login/options`, {
				goBackRoute: `/reservas/${business?.id}`,
				goBackRouteProps: props.location.state,
			});
		} else {
			props.history.push(`/reservas/${business?.id}`);
		}
	};

	const handleScheduleClick = () => {
		props.history.push(`/business/${business?.id}`);
	};

	const handleScheduleClickNotifications = () => {
		if (user && business && (user.isAnonymous || !user.uid)) {
			props.history.push(`/login/options`, {
				goBackRoute: `/notificacoes/${business?.id}`,
				goBackRouteProps: props.location.state,
			});
		} else {
			props.history.push(`/notificacoes/${business?.id}`);
		}
	};

	const handleScheduleClickIndications = () => {
		if (user && business && (user.isAnonymous || !user.uid)) {
			props.history.push(`/login/options`, { goBackRoute: `/indicacoes`, goBackRouteProps: props.location.state });
		} else {
			props.history.push(`/indicacoes`);
		}
	};

	const handleScheduleClickCoupon = () => {
		localStorage.setItem("@prit-web/NoCoupon", true);
		if (user && business && (user.isAnonymous || !user.uid)) {
			props.history.push(`/login/options`, { goBackRoute: `/cupons/${business?.id}`, goBackRouteProps: props.location.state });
		} else {
			props.history.push(`/cupons/${business?.id}`);
		}
	};

	return (
		<Fragment>
			<Divider />
			<div style={{ display: "flex", flexDirection: "row" }}>
				<Grid container justifycontent="center" className={classes.footer}>
					<Button
						className={coupons && coupons.items_list.length > 0 ? classes.buttonWithCoupon : classes.button}
						style={{ backgroundColor: props.section === "agendar" ? "rgb(136 136 136 / 28%)" : "#fff" }}
						onClick={() => handleScheduleClick()}>
						<HomeOutlined />
						<Typography className={classes.nameContainer}>{t("Home")}</Typography>
					</Button>
					<Button
						className={coupons && coupons.items_list.length > 0 ? classes.buttonWithCoupon : classes.button}
						style={{ backgroundColor: props.section === "reservas" ? "rgb(136 136 136 / 28%)" : "#fff" }}
						onClick={() => handleScheduleReservationsClick()}>
						<AgendaIcon />
						<Typography className={classes.nameContainer}>{t("Reservations")}</Typography>
					</Button>
					{coupons && coupons.items_list.length > 0 && (
						<Button
							className={coupons && coupons.items_list.length > 0 ? classes.buttonWithCoupon : classes.button}
							style={{ backgroundColor: props.section === "cupons" ? "rgb(136 136 136 / 28%)" : "#fff" }}
							onClick={() => {
								handleScheduleClickCoupon();
							}}>
							<ConfirmationNumberOutlinedIcon />
							<Typography className={classes.nameContainer}>{t("Coupons")}</Typography>
						</Button>
					)}

					<Button
						className={coupons && coupons.items_list.length > 0 ? classes.buttonWithCoupon : classes.button}
						style={{ backgroundColor: props.section === "indicacoes" ? "rgb(136 136 136 / 28%)" : "#fff" }}
						onClick={() => handleScheduleClickIndications()}>
						<Gift />
						<Typography className={classes.nameContainer}>{t("Indications")}</Typography>
					</Button>
					<Button
						className={coupons && coupons.items_list.length > 0 ? classes.buttonWithCoupon : classes.button}
						style={{ backgroundColor: props.section === "notificações" ? "rgb(136 136 136 / 28%)" : "#fff" }}
						onClick={() => handleScheduleClickNotifications()}>
						<NotificationsNoneIcon></NotificationsNoneIcon>
						<Typography className={classes.nameContainer}>{t("Notifications")} </Typography>
					</Button>
				</Grid>
			</div>
		</Fragment>
	);
};

export default withRouter(FooterContainer);
