import api from "~/services/api";

const CONTROLLER_NAME = "/panel_event";

export const postEvent = (data) => {
  return api.post(`${CONTROLLER_NAME}/`, data);
}

export default {
  postEvent,
};
