import React, { Fragment } from "react";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Dialog from "@mui/material/Dialog";
import { Button, Divider, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import useStyles from "../../checkout/styles";

import Utils from "~/helpers/Utils";

const ConfirmationPaymentPackage = ({infoPackage, business, open, nextMyAppointments, nextReserves }) => {
    const classes = useStyles();
	const { t } = useTranslation();
	return (
		<Dialog
			open={open}
			PaperProps={{
				style: { width: "28rem", height: "20rem", borderRadius: 20, padding: "1rem" },
			}}>
			<Fragment>
				<div
					style={{
						display: "flex",
						padding: "1rem",
						flexDirection: "column",
						gap: "1rem",
						position: "relative",
						height: "28rem",
					}}>
					<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
						<CheckCircle style={{ color: "green", fontSize: "80px" }}></CheckCircle>
						<Typography className={classes.professionalTitle} style={{ width: "60%", textAlign: "center" }}>
							{t("Package purchased successfully!")}
						</Typography>
					</div>
					<Divider />
					<div style={{ display: "flex", alignItems: "center", gap: "5px", justifyContent: "space-between" }}>
						<div>
							<Typography className={classes.professionalTitle}>&nbsp;{business.name}</Typography>
							<Typography className={classes.duration}>{infoPackage?.info?.packageName}</Typography>
						</div>
						<div>
							<Typography className={classes.price}>
								{Utils.getPostPrice(infoPackage?.body?.bookings[0]?.price * infoPackage?.info?.quantity, business?.currency)}
							</Typography>
						</div>
					</div>
					<div
						style={{
							display: "flex",
							position: "absolute",
							bottom: 20,
							left: 0,
							right: 0,
							justifyContent: "center",
							gap: "5px",
						}}>
						<Button
							type="button"
							variant="outlined"
							className={classes.root}
							style={{
								textTransform: "uppercase",
								fontWeight: "Bold",
								backgroundColor: "#fff",
								border: "2px solid #440067",
								color: "#440067",
							}}
							onClick={() => nextReserves()}>
							{t("My appointments")}
						</Button>
						<Button
							type="button"
							variant="contained"
							className={classes.root}
							style={{
								textTransform: "uppercase",
								fontWeight: "Bold",
								backgroundColor: "#440067",
								border: "2px solid #440067",
								color: "#fff",
							}}
							onClick={() => nextMyAppointments()}>
							{t("Buy more")}
						</Button>
					</div>
				</div>
			</Fragment>
		</Dialog>
	);
};

export default ConfirmationPaymentPackage;
