import dayjs from "~/config/dayjs";
import { getProfessional } from "~/services/professionalService";
import _ from "lodash";
import moment from "moment";
import { ConsoleView } from "react-device-detect";

import BandeiraALE from "assets/icons/Alemanha.png";
import BandeiraANG from "assets/icons/ANG.png";
import BandeiraARG from "assets/icons/ARG.jpg";
import BandeiraBRA from "assets/icons/BRA.png";
import BandeiraCAN from "assets/icons/CAN.png";
import BandeiraCHI from "assets/icons/CHI.png";
import BandeiraCOL from "assets/icons/COL.png";
import BandeiraDUB from "assets/icons/DUB.png";
import BandeiraEMI from "assets/icons/EMI.png";
import BandeiraESP from "assets/icons/ESP.png";
import BandeiraEUA from "assets/icons/EUA.png";
import BandeiraFRA from "assets/icons/FRA.png";
import BandeiraIND from "assets/icons/IND.png";
import BandeiraING from "assets/icons/ING.png";
import BandeiraIRL from "assets/icons/IRL.png";
import BandeiraITA from "assets/icons/ITA.png";
import BandeiraMEX from "assets/icons/MEX.png";
import BandeiraNLD from "assets/icons/NLD.png";
import BandeiraPAR from "assets/icons/PAR.png";
import BandeiraPER from "assets/icons/PER.png";
import BandeiraPOR from "assets/icons/POR.png";
import BandeiraURU from "assets/icons/URU.png";

/**
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @param {boolean=false} lower Whether all other letters should be lowercased
 * @return {string}
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
const capitalize = (str, lower = false) => {
	if (str === null) return "";
	return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
};

const trimString = (str) => {
	if (str === null) return "";
	const string = String(str);
	return string.trim();
};

const moneyMask = (value) => {
	return value.toLocaleString("pt-BR", {
		currency: "BRL",
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
	});
};

/**
 * Aplica máscara ao número de telefone com base no prefixo do país
 * @param {Event} e - Evento do input
 * @param {string} prefix - Prefixo: BRA, EUA, etc
 */
export const telephoneMask = (e, prefix) => {
  const country = ddis.find(country => country.prefix === prefix);  
  if (!country) {
    console.error(`Prefixo de país não encontrado: ${prefix}`);
    return;
  }
  const { ddi, pattern } = country;

  e.target.maxLength = pattern.maxLength;
  let value = e.target.value;
  let cleanValue = value.replace(/\D/g, '');  
  const ddiDigits = ddi.replace(/\D/g, '');

  // Não remove o primeiro dígito mesmo que seja igual ao DDI
	const removePrefix = country.pattern.removePrefix ?? true
  if (removePrefix && cleanValue.startsWith(ddiDigits)) {
    cleanValue = cleanValue.substring(ddiDigits.length);
  }

  const formatPositions = [];
  const mask = pattern.mask;

  for (let i = 0; i < mask.length; i++) {
    if ([' ', '-', '(', ')'].includes(mask[i])) {
      formatPositions.push({
        pos: i,
        char: mask[i]
      });
    }
  }

  let formattedValue = '';
  let numIndex = 0;
  
  for (let i = 0; i < mask.length && numIndex < cleanValue.length; i++) {
    const currentChar = mask[i];
    if ([' ', '-', '(', ')'].includes(currentChar)) {
      formattedValue += currentChar;
    } 
    else if (currentChar === 'X') {
      formattedValue += cleanValue[numIndex] || '';
      numIndex++;
    }
  }
  
  e.target.value = formattedValue;
};

export const numberMask = (e, length = 1) => {
	e.target.maxLength = length;
	let value = e.target.value;
	value = value.replace(/\D/g, "");
	e.target.value = value;
};

/**
 * Returns an array with the availability of the professional.
 * @param {Object} availPeriodProf Array of availability period of the professional
 * @param {Object} scheduledServices Array of scheduledServices chosen by the user
 * @param {Number} timeLapse Time between slots chosen by the business
 * @param {Boolean} isAvailableTomorrow Professional works 24 hours or to the next day
 * @param {Number} serviceDuration Duration of the service
 * @param {Object} prof Professional information
 * @return {Array}
 */
const makeSlotProfMultService = (
	availProfApi,
	availPeriodProf,
	scheduledServices = [],
	timeLapse = 0,
	isAvailableTomorrow = false,
	serviceDuration,
	prof
) => {
	// ATUALIZA O PERÍODO - INÍCIO
	if (scheduledServices.length !== 0 && availPeriodProf !== null) {
		let newAvailPeriodProf = _.cloneDeep(availPeriodProf);
		const newScheduleMark = _.cloneDeep(
			scheduledServices.map((item) => {
				return {
					start: moment(item.date).toISOString(),
					end: moment(item.date).add(item.service.serviceDuration, "minutes").toISOString(),
					profId: item.professional_id,
				};
			})
		);

		newScheduleMark.forEach((item) => {
			if (prof.id === item.profId) {
				prof.schedule.map((i, index) => {
					const auxNewAvailPeriodProf = [];
					let aux = true;
					newAvailPeriodProf.forEach((ele) => {
						if (moment(item.start).isSameOrAfter(ele.start) && moment(item.end).isSameOrBefore(ele.end) && aux) {
							if (moment(ele.start).isBefore(item.start)) {
								if (moment(item.end).isBefore(ele.end)) {
									auxNewAvailPeriodProf.push({ start: ele.start, end: item.start, profId: item.profId });
									auxNewAvailPeriodProf.push({ start: item.end, end: ele.end, profId: item.profId });
									aux = false;
								} else {
									auxNewAvailPeriodProf.push({ start: ele.start, end: item.start, profId: item.profId });
									aux = false;
								}
							} else {
								if (moment(item.end).isBefore(ele.end)) {
									auxNewAvailPeriodProf.push({ start: item.end, end: ele.end, profId: item.profId });
									aux = false;
								} else {
									aux = false;
								}
							}
						} else {
							auxNewAvailPeriodProf.push(ele);
						}
					});
					newAvailPeriodProf = _.cloneDeep(auxNewAvailPeriodProf);
				});
			}
		});
		// ATUALIZA O PERÍODO - FINAL
		// CRIA OS SLOTS - INÍCIO
		const timeBetweenSlots = _.isNil(timeLapse) || timeLapse === 0 ? serviceDuration : timeLapse;
		const slots = [];

		newAvailPeriodProf.forEach((item) => {
			const startPeriod = _.cloneDeep(moment(item.start));
			const endPeriod = _.cloneDeep(moment(item.end));

			const result = [];
			const startSlot = _.cloneDeep(moment(startPeriod));
			let endSlot = _.cloneDeep(moment(startPeriod));
			endSlot.add(serviceDuration, "minutes");

			while (endSlot <= endPeriod) {
				let initial = null;
				initial = { start: startSlot.toISOString(), end: endSlot.toISOString() };

				result.push(initial);
				startSlot.add(timeBetweenSlots, "minutes");
				endSlot = startSlot.add(serviceDuration, "minutes");
			}
			if (isAvailableTomorrow && moment() < endSlot) {
				if (moment(endSlot) < endPeriod) {
					result.push({ start: startSlot.toISOString(), end: endSlot.toISOString() });
				}
			}
			slots.push(...result);
		});
		let timeSteps = [];
		slots.forEach((item, index) => {
			timeSteps.push({ time: dayjs(item.start), professionalId: prof.id });
		});
		return timeSteps.sort((a, b) => {
			return sortDates(a.time, b.time);
		});
		// CRIA OS SLOTS - FINAL
	}
	return [];
};

/**
 * Returns an array with the availability of the professional.
 * @param {Object} availProfApi Array of availability of the professional
 * @param {String} profId Professional ID
 * @return {Array}
 */

const makeTimeSteps = (availProfApi, servicesScheduled, prof, business) => {
	if (!_.isEmpty(servicesScheduled))
		return makeSlotProfMultService(
			availProfApi,
			prof.availability,
			servicesScheduled,
			business.timeLapse,
			prof.isAvailableTomorrow,
			prof.duration,
			prof
		);

	let timeSteps = [];
	availProfApi.forEach((item, index) => {
		timeSteps.push({ time: dayjs(item[0].start), professionalId: prof.id });
	});

	return timeSteps.sort((a, b) => {
		return sortDates(a.time, b.time);
	});
};

/**
 * Returns an array with the availability of all professionals of that service.
 * @param {Object} professionals Array of professionals of the service
 * @return {Array}
 */
const makeTimeStepsWithProfId = (professionals, service, servicesScheduled, business) => {
	let timeSteps = [];
	professionals.forEach((profItem) => {
		const profId = profItem.id;
		if (!_.isEmpty(servicesScheduled)) {
			const result = makeTimeSteps(profItem.schedule, servicesScheduled, profItem, business);
			result.forEach((element) => {
				timeSteps.push({
					time: element.time,
					professionalId: profId,
					professional: {
						duration: profItem.duration,
						id: profItem.id,
						name: profItem.name,
						price: profItem.price,
						pricingType: profItem.pricingType,
					},
					service: service,
				});
			});
		} else {
			profItem.schedule.forEach((element) => {
				timeSteps.push({
					time: dayjs(element[0].start),
					professionalId: profId,
					professional: {
						duration: profItem.duration,
						id: profItem.id,
						name: profItem.name,
						price: profItem.price,
						pricingType: profItem.pricingType,
					},
					service: service,
				});
			});
		}
	});

	return timeSteps.sort((a, b) => {
		return sortDates(a.time, b.time);
	});
};

const sortDates = (dateA, dateB) => {
	if (dateA.isBefore(dateB)) {
		return -1;
	} else if (dateB.isBefore(dateA)) {
		return 1;
	} else {
		return 0;
	}
};

const objectIsNull = (obj) => {
	if (obj === undefined) return true;
	return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const fireBaseErrors = (code) => {
	const params = {
		"auth/app-deleted": "O banco de dados não foi localizado.",
		"auth/expired-action-code": "O código da ação o ou link expirou.",
		"auth/invalid-action-code":
			"O código da ação é inválido. Isso pode acontecer se o código estiver malformado ou já tiver sido usado.",
		"auth/user-disabled": "O usuário correspondente à credencial fornecida foi desativado.",
		"auth/user-not-found": "O usuário não correponde à nenhuma credencial.",
		"auth/weak-password": "A senha é muito fraca.",
		"auth/email-already-in-use": "Já existi uma conta com o endereço de email fornecido.",
		"auth/invalid-email": "O endereço de e-mail não é válido.",
		"auth/operation-not-allowed": "O tipo de conta correspondente à esta credencial, ainda não encontra-se ativada.",
		"auth/account-exists-with-different-credential": "E-mail já associado a outra conta.",
		"auth/auth-domain-config-required": "A configuração para autenticação não foi fornecida.",
		"auth/credential-already-in-use": "Já existe uma conta para esta credencial.",
		"auth/operation-not-supported-in-this-environment":
			"Esta operação não é suportada no ambiente que está sendo executada. Verifique se deve ser http ou https.",
		"auth/timeout": "Excedido o tempo de resposta. O domínio pode não estar autorizado para realizar operações.",
		"auth/missing-android-pkg-name": "Deve ser fornecido um nome de pacote para instalação do aplicativo Android.",
		"auth/missing-continue-uri": "A próxima URL deve ser fornecida na solicitação.",
		"auth/missing-ios-bundle-id": "Deve ser fornecido um nome de pacote para instalação do aplicativo iOS.",
		"auth/invalid-continue-uri": "A próxima URL fornecida na solicitação é inválida.",
		"auth/unauthorized-continue-uri": "O domínio da próxima URL não está na lista de autorizações.",
		"auth/invalid-dynamic-link-domain":
			"O domínio de link dinâmico fornecido, não está autorizado ou configurado no projeto atual.",
		"auth/argument-error": "Verifique a configuração de link para o aplicativo.",
		"auth/invalid-persistence-type": "O tipo especificado para a persistência dos dados é inválido.",
		"auth/unsupported-persistence-type":
			"O ambiente atual não suportar o tipo especificado para persistência dos dados.",
		"auth/invalid-credential": "A credencial expirou ou está mal formada.",
		"auth/wrong-password": "Senha incorreta.",
		"auth/invalid-verification-code": "O código informado está incorreto. Por favor, verifique o SMS e tente novamente",
		"auth/invalid-verification-id": "O ID de verificação da credencial não é válido.",
		"auth/custom-token-mismatch": "O token está diferente do padrão solicitado.",
		"auth/invalid-custom-token": "O token fornecido não é válido.",
		"auth/captcha-check-failed": "O token de resposta do reCAPTCHA não é válido, expirou ou o domínio não é permitido.",
		"auth/invalid-phone-number": "O número de telefone está em um formato inválido.",
		"auth/missing-phone-number": "O número de telefone é requerido.",
		"auth/quota-exceeded": "A cota de SMS foi excedida.",
		"auth/cancelled-popup-request": "Somente uma solicitação de janela pop-up é permitida de uma só vez.",
		"auth/popup-blocked": "A janela pop-up foi bloqueado pelo navegador.",
		"auth/popup-closed-by-user": "A janela pop-up foi fechada pelo usuário sem concluir o login no provedor.",
		"auth/unauthorized-domain": "O domínio do aplicativo não está autorizado para realizar operações.",
		"auth/invalid-user-token": "O usuário atual não foi identificado.",
		"auth/user-token-expired": "O token do usuário atual expirou.",
		"auth/null-user": "O usuário atual é nulo.",
		"auth/app-not-authorized": "Aplicação não autorizada para autenticar com a chave informada.",
		"auth/invalid-api-key": "A chave da API fornecida é inválida.",
		"auth/network-request-failed": "Falha de conexão com a rede.",
		"auth/requires-recent-login": "O último horário de acesso do usuário não atende ao limite de segurança.",
		"auth/too-many-requests":
			"As solicitações foram bloqueadas devido a várias tentativas. Tente novamente mais tarde.",
		"auth/web-storage-unsupported": "O navegador não suporta armazenamento ou se o usuário desativou este recurso.",
		"auth/invalid-claims": "Os atributos de cadastro personalizado são inválidos.",
		"auth/claims-too-large": "O tamanho da requisição excede o tamanho máximo permitido de 1 Megabyte.",
		"auth/id-token-expired": "O token informado expirou.",
		"auth/id-token-revoked": "O token informado perdeu a validade.",
		"auth/invalid-argument": "Um argumento inválido foi fornecido a um método.",
		"auth/invalid-creation-time": "O horário da criação precisa ser uma data UTC válida.",
		"auth/invalid-disabled-field": "A propriedade para usuário desabilitado é inválida.",
		"auth/invalid-display-name": "O nome do usuário é inválido.",
		"auth/invalid-email-verified": "O e-mail é inválido.",
		"auth/invalid-hash-algorithm": "O algoritmo de HASH não é uma criptografia compatível.",
		"auth/invalid-hash-block-size": "O tamanho do bloco de HASH não é válido.",
		"auth/invalid-hash-derived-key-length": "O tamanho da chave derivada do HASH não é válido.",
		"auth/invalid-hash-key": "A chave de HASH precisa ter um buffer de byte válido.",
		"auth/invalid-hash-memory-cost": "O custo da memória HASH não é válido.",
		"auth/invalid-hash-parallelization": "O carregamento em paralelo do HASH não é válido.",
		"auth/invalid-hash-rounds": "O arredondamento de HASH não é válido.",
		"auth/invalid-hash-salt-separator":
			"O campo do separador de SALT do algoritmo de geração de HASH precisa ser um buffer de byte válido.",
		"auth/invalid-id-token": "O código do token informado não é válido.",
		"auth/invalid-last-sign-in-time": "O último horário de login precisa ser uma data UTC válida.",
		"auth/invalid-page-token": "A próxima URL fornecida na solicitação é inválida.",
		"auth/invalid-password": "A senha é inválida, precisa ter pelo menos 6 caracteres.",
		"auth/invalid-password-hash": "O HASH da senha não é válida.",
		"auth/invalid-password-salt": "O SALT da senha não é válido.",
		"auth/invalid-photo-url": "A URL da foto de usuário é inválido.",
		"auth/invalid-provider-id": "O identificador de provedor não é compatível.",
		"auth/invalid-session-cookie-duration":
			"A duração do COOKIE da sessão precisa ser um número válido em milissegundos, entre 5 minutos e 2 semanas.",
		"auth/invalid-uid": "O identificador fornecido deve ter no máximo 128 caracteres.",
		"auth/invalid-user-import": "O registro do usuário a ser importado não é válido.",
		"auth/invalid-provider-data": "O provedor de dados não é válido.",
		"auth/maximum-user-count-exceeded": "O número máximo permitido de usuários a serem importados foi excedido.",
		"auth/missing-hash-algorithm":
			"É necessário fornecer o algoritmo de geração de HASH e seus parâmetros para importar usuários.",
		"auth/missing-uid": "Um identificador é necessário para a operação atual.",
		"auth/reserved-claims": "Uma ou mais propriedades personalizadas fornecidas usaram palavras reservadas.",
		"auth/session-cookie-revoked": "O COOKIE da sessão perdeu a validade.",
		"auth/uid-alread-exists": "O indentificador fornecido já está em uso.",
		"auth/email-already-exists": "O e-mail fornecido já está em uso.",
		"auth/phone-number-already-exists": "O telefone fornecido já está em uso.",
		"auth/project-not-found": "Nenhum projeto foi encontrado.",
		"auth/insufficient-permission": "A credencial utilizada não tem permissão para acessar o recurso solicitado.",
		"auth/internal-error": "O servidor de autenticação encontrou um erro inesperado ao tentar processar a solicitação.",
	};

	return params[code] || code;
};

const resetAllRedirectionsAfterLogin = () => {
	localStorage.removeItem("@prit-web/checkoutTrue", false);
	localStorage.removeItem("@prit-web/coupon", false);
	localStorage.removeItem("@prit-web/bookingTransactionId", false);
	localStorage.removeItem("@prit-web/vendorPage", false);
};

const ddis = [
	{
		prefix: "BRA",
		ddi: "+55",
		img: BandeiraBRA,
		pattern: {
			mask: "(XX) XXXXX-XXXX",
			regex: /^\(\d{2}\)\s\d{5}-\d{4}$/,
			maxLength: 15
		}
	},
	{
		prefix: "EUA",
		ddi: "+1",
		img: BandeiraEUA,
		pattern: {
			mask: "(XXX) XXX-XXXX",
			regex: /^\(\d{3}\)\s\d{3}-\d{4}$/,
			maxLength: 14,
			removePrefix: false
		}
	},
	{
		prefix: "CAN",
		ddi: "+1",
		img: BandeiraCAN,
		pattern: {
			mask: "(XXX) XXX-XXXX",
			regex: /^\(\d{3}\)\s\d{3}-\d{4}$/,
			maxLength: 14,
			removePrefix: false
		}
	},
	{
		prefix: "NLD",
		ddi: "+31",
		img: BandeiraNLD,
		pattern: {
			mask: "X-XXXX-XXXX",
			regex: /^\d{1}-\d{4}-\d{4}$/,
			maxLength: 11
		}
	},
	{
		prefix: "FRA",
		ddi: "+33",
		img: BandeiraFRA,
		pattern: {
			mask: "X XX XX XX XX",
			regex: /^\d{1}\s\d{2}\s\d{2}\s\d{2}\s\d{2}$/,
			maxLength: 14
		}
	},
	{
		prefix: "ESP",
		ddi: "+34",
		img: BandeiraESP,
		pattern: {
			mask: "XXX XXX XXX",
			regex: /^\d{3}\s\d{3}\s\d{3}$/,
			maxLength: 11
		}
	},
	{
		prefix: "ITA",
		ddi: "+39",
		img: BandeiraITA,
		pattern: {
			mask: "XXX XXX XXXX",
			regex: /^\d{3}\s\d{3}\s\d{4}$/,
			maxLength: 12
		}
	},
	{
		prefix: "ING",
		ddi: "+44",
		img: BandeiraING,
		pattern: {
			mask: "XXXX XXXXXX",
			regex: /^\d{4}\s\d{6}$/,
			maxLength: 11
		}
	},
	{
		prefix: "ALE",
		ddi: "+49",
		img: BandeiraALE,
		pattern: {
			mask: "XXX XXXXXXX",
			regex: /^\d{3}\s\d{7}$/,
			maxLength: 11
		}
	},
	{
		prefix: "PER",
		ddi: "+51",
		img: BandeiraPER,
		pattern: {
			mask: "XXX XXX-XXX",
			regex: /^\d{3}\s\d{3}-\d{3}$/,
			maxLength: 11
		}
	},
	{
		prefix: "MEX",
		ddi: "+52",
		img: BandeiraMEX,
		pattern: {
			mask: "(XXX) XXX-XXXX",
			regex: /^\(\d{3}\)\s\d{3}-\d{4}$/,
			maxLength: 14
		}
	},
	{
		prefix: "ARG",
		ddi: "+54",
		img: BandeiraARG,
		pattern: {
			mask: "X (XX) XXXX-XXXX",
			regex: /^\d{1}\s\(\d{2}\)\s\d{4}-\d{4}$/,
			maxLength: 16
		}
	},
	{
		prefix: "CHI",
		ddi: "+56",
		img: BandeiraCHI,
		pattern: {
			mask: "X XXXX-XXXX",
			regex: /^\d{1}\s\d{4}-\d{4}$/,
			maxLength: 10
		}
	},
	{
		prefix: "COL",
		ddi: "+57",
		img: BandeiraCOL,
		pattern: {
			mask: "(XXX) XXX-XXXX",
			regex: /^\(\d{3}\)\s\d{3}-\d{4}$/,
			maxLength: 14
		}
	},
	{
		prefix: "IND",
		ddi: "+91",
		img: BandeiraIND,
		pattern: {
			mask: "XXXXX-XXXXX",
			regex: /^\d{5}-\d{5}$/,
			maxLength: 11
		}
	},
	{
		prefix: "ANG",
		ddi: "+244",
		img: BandeiraANG,
		pattern: {
			mask: "XXX XXX XXX",
			regex: /^\d{3}\s\d{3}\s\d{3}$/,
			maxLength: 11
		}
	},
	{
		prefix: "POR",
		ddi: "+351",
		img: BandeiraPOR,
		pattern: {
			mask: "XXX XXX XXX",
			regex: /^\d{3}\s\d{3}\s\d{3}$/,
			maxLength: 11
		}
	},
	{
		prefix: "IRL",
		ddi: "+353",
		img: BandeiraIRL,
		pattern: {
			mask: "XX XXX XXXX",
			regex: /^\d{2}\s\d{3}\s\d{4}$/,
			maxLength: 10
		}
	},
	{
		prefix: "PAR",
		ddi: "+595",
		img: BandeiraPAR,
		pattern: {
			mask: "X XXXX-XXXX",
			regex: /^\d{1}\s\d{4}-\d{4}$/,
			maxLength: 10
		}
	},
	{
		prefix: "URU",
		ddi: "+598",
		img: BandeiraURU,
		pattern: {
			mask: "X XXX-XXXX",
			regex: /^\d{1}\s\d{3}-\d{4}$/,
			maxLength: 9
		}
	},
	{
		prefix: "DUB",
		ddi: "+971",
		img: BandeiraDUB,
		pattern: {
			mask: "(XX) XXX XXXX",
			regex: /^\(\d{2}\)\s\d{3}\s\d{4}$/,
			maxLength: 13
		}
	},
	{
		prefix: "EMI",
		ddi: "+971",
		img: BandeiraEMI,
		pattern: {
			mask: "(XX) XXX XXXX",
			regex: /^\(\d{2}\)\s\d{3}\s\d{4}$/,
			maxLength: 13
		}
	},
];

const formatValues = (value) => {
	if (value === 0) {
		return "0,00";
	}
	return value ? moneyMask(value / 100) : "";
};

const getTotalPricingType = (services) => {
	let isGratuito = true;
	for (let i = 0; i < services?.length; i++) {
		if (services[i]?.professional?.pricingType === "Não mostrar") {
			return "Não mostrar";
		}
		if (services[i]?.professional?.pricingType !== "Gratuito") {
			isGratuito = false;
		}
	}
	return isGratuito ? "Gratuito" : "Fixo";
};

const getServicePricingType = (service, business) => {
	if (service?.pricingTypeName === "Não mostrar") {
		return "Sob consulta";
	}
	if (service?.pricingTypeName === "Gratuito") {
		return "Gratuito";
	}

	return parseFloat(service?.price / 100).toLocaleString("pt-BR", { style: "currency", currency: business.currency });
};

const getTotalPrice = (checkoutInformation, totalPricingType, currency, coupon) => {
	return `${
		totalPricingType && /o mostrar/gi.test(totalPricingType)
			? "Sob consulta"
			: `${totalPricingType && totalPricingType !== "Fixo" ? totalPricingType : ""} ${
					totalPricingType !== "Gratuito"
						? parseFloat(checkoutInformation ? checkoutInformation?.total_value_for_services / 100 : 0).toLocaleString(
								"pt-BR",
								{
									style: "currency",
									currency: currency,
								}
						  )
						: parseFloat(0).toLocaleString("pt-BR", {
								style: "currency",
								currency: currency,
						  })
			  }`
	}`;
};

const getDiscountPrice = (checkoutInformation, totalPricingType, currency, coup) => {
	var coupon = coup?.coupon;
	return `${
		coupon?.fixed && /o mostrar/gi.test(coupon?.fixed)
			? "Sob consulta"
			: `${coupon?.fixed && totalPricingType && totalPricingType !== "Fixo" ? totalPricingType : ""} ${
					coupon?.fixed && totalPricingType !== "Gratuito"
						? parseFloat(formatValues(coupon?.fixed)).toLocaleString("pt-BR", {
								style: "currency",
								currency: currency,
						  })
						: `${
								coupon?.percentage
									? `${parseFloat(
											formatValues(
												(checkoutInformation ? checkoutInformation?.total_value_for_services / 100 : 0) *
													coupon?.percentage
											)
									  ).toLocaleString("pt-BR", {
											style: "currency",
											currency: currency,
									  })}`
									: `${
											!coupon && totalPricingType && /o mostrar/gi.test(totalPricingType)
												? "Sob consulta"
												: `${!coupon && totalPricingType !== "Fixo" ? totalPricingType : ""} ${
														!coupon && totalPricingType !== "Gratuito"
															? parseFloat(
																	checkoutInformation ? checkoutInformation?.discounts_payment / 100 : 0
															  ).toLocaleString("pt-BR", {
																	style: "currency",
																	currency: currency,
															  })
															: parseFloat(0).toLocaleString("pt-BR", {
																	style: "currency",
																	currency: currency,
															  })
												  }`
									  }`
						  }`
			  }`
	}`;
};

const getAntecipatedPrice = (checkoutInformation, totalPricingType, currency, coupon) => {
	return `${`${
		checkoutInformation?.anticipation_payment_percentage !== 0
			? parseFloat(0).toLocaleString("pt-BR", {
					style: "currency",
					currency: currency,
			  })
			: totalPricingType && /o mostrar/gi.test(totalPricingType)
			? "Sob consulta"
			: `${totalPricingType && totalPricingType !== "Fixo" ? totalPricingType : ""} ${
					totalPricingType !== "Gratuito"
						? parseFloat(checkoutInformation ? checkoutInformation?.anticipation_payment / 100 : 0).toLocaleString(
								"pt-BR",
								{
									style: "currency",
									currency: currency,
								}
						  )
						: ""
			  }`
	}`}`;
};

const getPostPrice = (amount, currency) => {
	return `${parseFloat(amount / 100).toLocaleString("pt-BR", {
		style: "currency",
		currency: currency,
	})}`;
};

const getBalancePrice = (checkoutInformation, totalPricingType, currency, coup) => {
	var coupon = coup?.coupon;
	return `${
		coupon?.fixed && /o mostrar/gi.test(coupon?.fixed)
			? "Sob consulta"
			: `${coupon?.fixed && totalPricingType !== "Fixo" ? totalPricingType : ""} ${
					coupon?.fixed && totalPricingType !== "Gratuito"
						? parseFloat(
								(
									!checkoutInformation?.anticipation_payment
										? checkoutInformation?.total_value_for_services - coupon?.fixed
										: checkoutInformation?.total_value_for_services -
												coupon?.fixed -
												checkoutInformation?.anticipation_payment
								) / 100
						  ).toLocaleString("pt-BR", {
								style: "currency",
								currency: currency,
						  })
						: `${
								coupon?.percentage
									? `${parseFloat(
											(
												checkoutInformation?.total_value_for_services -
													((checkoutInformation?.total_value_for_services * coupon?.percentage) / 100 +
														checkoutInformation?.anticipation_payment)
											) / 100
									  ).toLocaleString("pt-BR", {
											style: "currency",
											currency: currency,
									  })}`
									: `${
											totalPricingType && /o mostrar/gi.test(totalPricingType)
												? "Sob consulta"
												: `${totalPricingType !== "Fixo" ? totalPricingType : ""} ${
														totalPricingType !== "Gratuito"
															? `${
																	checkoutInformation?.total_discounts > 0
																		? parseFloat(
																				checkoutInformation ? checkoutInformation?.total_value_for_services / 100 : 0
																		  ).toLocaleString("pt-BR", {
																				style: "currency",
																				currency: currency,
																		  })
																		: parseFloat(
																				checkoutInformation ? checkoutInformation?.balance_to_pay / 100 : 0
																		  ).toLocaleString("pt-BR", {
																				style: "currency",
																				currency: currency,
																		  })
															  }`
															: ""
												  }`
									  }`
						  }`
			  }`
	}`;
};

const getProfessionalPrice = (professional, service, currency) => {
	let amount = professional?.price ?? 0;

	if (service.servicePrice > amount) {
		amount = service.servicePrice;
	}

	return `${
		professional?.pricingType &&
		professional?.pricingType !== undefined &&
		/o mostrar/gi.test(professional?.pricingType)
			? "Sob consulta"
			: `${
					professional?.pricingType !== "Fixo" && professional?.pricingType !== undefined
						? professional?.pricingType
						: ""
			  } ${
					professional?.pricingType !== "Gratuito"
						? parseFloat(amount / 100).toLocaleString("pt-BR", {
								style: "currency",
								currency: currency,
						  })
						: ""
			  }`
	}`;
};

const policyDetails = (checkoutInformation, currency) => {
	if (checkoutInformation?.used_percentage_value && !checkoutInformation?.used_fixed_value) {
		return checkoutInformation?.hours_before_booking_expiration > 0
			? `Caso esta reserva seja cancelada em até ${checkoutInformation?.hours_before_booking_expiration}h antes do horário reservado, será devolvido o valor pago antecipadamente.`
			: "Caso esta reserva seja cancelada, não será devolvido o valor pago antecipadamente.";
	} else if (
		checkoutInformation?.used_fixed_value &&
		!checkoutInformation?.used_percentage_value &&
		checkoutInformation?.hours_before_booking_expiration > 0
	) {
		return `Caso esta reserva seja cancelada em até ${
			checkoutInformation?.hours_before_booking_expiration
		}h antes do horário reservado, será devolvido o valor pago de ${parseFloat(
			checkoutInformation?.value_cancellation_fee / 100
		).toLocaleString("pt-BR", { style: "currency", currency: currency })}.`;
	} else {
		return `Caso esta reserva seja cancelada, não será devolvido o valor pago antecipadamente.`;
	}
};

const formatMinutes = (minutes) => {
	const hours = Math.floor(minutes / 60);
	const min = minutes % 60;
	const textHours = `${hours}`.slice(-2);
	const textMin = `00${min}`.slice(-2);

	return textHours === "0" ? ` ${textMin}m` : `${textHours}h ${textMin}m`;
};

const formatCardNumber = (cardNumber) =>
	cardNumber ? cardNumber.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, "************$4") : "";

const validateCPF = (cpf) => {
	if (cpf.length !== 11) {
		return false;
	}

	let numeros = cpf.substring(0, 9);
	let digitos = cpf.substring(9);

	let soma = 0;
	for (let i = 10; i > 1; i--) {
		soma += numeros.charAt(10 - i) * i;
	}

	let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

	if (resultado !== Number(digitos.charAt(0))) {
		return false;
	}

	soma = 0;
	numeros = cpf.substring(0, 10);

	for (let k = 11; k > 1; k--) {
		soma += numeros.charAt(11 - k) * k;
	}
	resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

	if (resultado !== Number(digitos.charAt(1))) {
		return false;
	}

	return true;
};

export default {
	formatCardNumber,
	formatMinutes,
	policyDetails,
	getProfessionalPrice,
	getBalancePrice,
	getAntecipatedPrice,
	getDiscountPrice,
	getTotalPrice,
	getTotalPricingType,
	getServicePricingType,
	getPostPrice,
	capitalize,
	trimString,
	moneyMask,
	makeTimeSteps,
	makeTimeStepsWithProfId,
	objectIsNull,
	telephoneMask,
	numberMask,
	fireBaseErrors,
	resetAllRedirectionsAfterLogin,
	ddis,
	validateCPF,
};
