import React, { Fragment, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import useStyles from "../checkout/styles";

import { useTranslation } from "react-i18next";

import { Avatar, Button, Divider, Typography, Grid } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import GoogleIcon from "@mui/icons-material/Google";

import { DialogBuilder } from "~/utils/DialogProvider";
import DialogPix from "../common/dialog/dialogPix";
import DialogDiscount from "../common/dialog/dialogDiscount";
import DialogPayConfirmPackage from "../common/dialog/dialogPayConfirmPackage";
import dialogPayCod from "../common/dialog/dialogPayCod";
import ConfirmationPaymentPackage from "../common/confirmationPaymentPackage/ConfirmationPaymentPackage";

import timeIcon from "assets/icons/group-4.png";
import TitleContainer from "~/components/common/titleContainer/TitleContainer";
import DialogAddCard from "../common/dialog/dialogAddCard";

import Utils from "~/helpers/Utils";

import checkoutServices from "~/services/checkoutServices";
import businessService from "~/services/businessService";
import bookingTransactionService from "~/services/bookingTransactionService";

import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import SEO from "~/components/seo";

const CheckoutPackage = (props) => {
	const { business } = useSelector((store) => store.business);
	const currency = business?.currency;
	const infoPackage = props?.location?.state;
	const dialog = useContext(DialogBuilder);
	const me = JSON.parse(localStorage.getItem("@prit-web/meData"));
	const { user } = useSelector((store) => store.auth);

	const [checkoutInformation, setCheckoutInformation] = useState(null);
	const [contract, setContract] = useState();
	const [offerAccepted, setOfferAccepted] = useState(false);
	const [cardData, setCardData] = useState();
	const [pix, setPix] = useState();
	const [pixCode, setPixCode] = useState();
	const [showUpdateInfo, setShowUpdateInfo] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [showError, setShowError] = useState(false);

	const { t } = useTranslation();

	const classes = useStyles();

	const [loading, setLoading] = useState(false);

	const [open, setOpen] = useState(false);

	const provider = new GoogleAuthProvider();
	const auth = getAuth();

	const login = async () => {
		try {
			await signInWithPopup(auth, provider);

			const res = await checkoutServices.me();

			if (res?.data?.id) {
				localStorage.setItem("@prit-web/meData", JSON.stringify(res.data));
				localStorage.setItem("@prit-web/userId", res.data.id);

				if (!res.data.phoneUnverified) {
					props.history.replace("/login/personal-information-number", {
						goBackRoute: "/checkout-package/" + business.id,
						goBackRouteProps: props.location.state,
					});
				} else {
					window.location.reload();
				}
			}
		} catch (error) {
			alert(`Erro durante o login com Google: ${error.message}`);
		}
	};

	const validateAdvancedBilling = (checkoutInformation) => {
		if (
			checkoutInformation?.cancellation_policy?.fixed_cancellation ||
			checkoutInformation?.cancellation_policy?.percentage_cancellation ||
			checkoutInformation?.cancellation_policy?.anticipated_payment
		) {
			return true;
		}
		return false;
	};

	const footer = () => {
		return (
			<Fragment>
				{!me?.name ? (
					<Typography style={{ padding: 20, fontSize: 14 }}>
						Para <strong>confirmar o agendamento</strong>, escolha uma das opções abaixo
					</Typography>
				) : (
					<div style={{ padding: 20 }}>
						{(validateAdvancedBilling(checkoutInformation)) &&
							user &&
							!user.isAnonymous && (
								<Fragment>
									<Typography className={classes.policyWrapper}>
										{checkoutInformation?.value_cancellation_fee ? "Política de Cancelamento" : ""}
									</Typography>
									<Typography className={classes.policyWrapper}>
										{Utils.policyDetails(checkoutInformation, currency)}
									</Typography>
								</Fragment>
							)}
					</div>
				)}

				<div style={{ padding: "0 20px 20px 20px" }}>
					{checkoutInformation == null ? (
						<div></div>
					) : user &&
					  !user.isAnonymous &&
					  me?.name &&
					  (validateAdvancedBilling(checkoutInformation) ||
							(validateAdvancedBilling(checkoutInformation) &&
								offerAccepted)) ? (
						<div style={{ textAlign: "end" }}>
							{business?.currency !== "BRL" ? (
								<Grid>
									{!_.isNil(contract) && !_.isEmpty(contract) && !_.isNull(contract?.transaction_percentage) && (
										<Button
											onClick={() => {
												if (user && !user.isAnonymous) {
													dialog.withContent(DialogPayConfirmPackage).show();
												}
											}}
											style={{ width: "100%", float: "left", marginRight: "1px" }}
											className={classes.root}>
											{"Fazer pagamento"}
										</Button>
									)}
								</Grid>
							) : (
								<div style={{ justifyContent: "space-evenly", width: "100%", display: "flex" }}>
									{!_.isNull(contract?.transaction_percentage) && (
										<Button
											onClick={() => {
												if (user && !user.isAnonymous) {
													setPix(null);
													setCardData(null);
													dialog
														.withContent(DialogAddCard)
														.withProps({
															setCardData,
														})
														.show();
												}
											}}
											style={{
												width: contract?.pix_transaction_percentage !== null ? "45%" : "100%",
												float: "left",
												marginRight: "1px",
											}}
											className={classes.root}>
											{t("Pay with card")}
										</Button>
									)}
									{!_.isNull(contract?.pix_transaction_percentage) && (
										<Button
											onClick={() => {
												if (user && !user.isAnonymous) {
													setPix(null);
													setCardData(null);
													dialog.withContent(DialogPix).withProps({ setPix }).show();
												}
											}}
											style={{
												width: contract?.transaction_percentage !== null ? "45%" : "100%",
												marginRight: "1px",
											}}
											className={classes.root}>
											{t("Pay with pix")}
										</Button>
									)}
								</div>
							)}
						</div>
					) : (
						<div style={{ width: "100%", justifyContent: "center", display: "grid" }}>
							{process.env.REACT_APP_GOOGLE_LOGIN_ENABLED === "true" && !me?.name && (
								<Button
									disabled={false}
									className={classes.root}
									style={{
										textTransform: "uppercase",
										fontWeight: "Bold",
										marginBottom: "5%",
									}}
									onClick={() => login()}>
									<GoogleIcon style={{ position: "absolute", left: 16 }} />
									<span style={{ flexGrow: 1, textAlign: "center" }}>{t("Continue Google")}</span>
								</Button>
							)}
							<Button
								onClick={() => {
									if (!me || !me?.name || !user || user.isAnonymous) {
										localStorage.setItem("goBackRoute", "/checkout-package/" + business.id);
										props.history.push(`/login`, {
											goBackRoute: "/checkout-package/" + business.id,
											goBackRouteProps: props.location.state,
										});
										return;
									} else if (
										validateAdvancedBilling(checkoutInformation)
									) {
										dialog
											.withContent(DialogDiscount)
											.withProps({
												acceptOffer: () => {
													setOfferAccepted(true);
												},
												refuseOffer: () => {
													postBooking();
												},
												checkoutInformation,
												currency,
											})
											.show();
									} else {
										postBooking();
									}
								}}
								disabled={false}
								className={classes.root}
								style={{
									textTransform: "uppercase",
									fontWeight: "Bold",
									backgroundColor: me?.name ? "#440067" : "#fff",
									border: "2px solid #440067",
									color: me?.name ? "#fff" : "#440067",
								}}>
								{!me?.name ? <PhoneIcon style={{ position: "absolute", left: 16 }} /> : ""}
								{me?.name ? t("Buy package") : t("Continue")}
							</Button>
						</div>
					)}
				</div>
			</Fragment>
		);
	};

	const roundPricesDown = (body, bookingTotalPrice) => {
		const bookings = body?.bookings;

		if (!bookings || !Array.isArray(bookings)) {
		  return body; 
		}

		const roundedBookings = bookings.map(item => {
		  const price = Number(item.price) || 0;
		  return {
			...item,
			price: Math.floor(price),
		  };
		});
	  
		const sumRoundedPrices = roundedBookings.reduce((total, booking) => total + booking.price, 0);
		const difference = bookingTotalPrice - sumRoundedPrices;
	  
		if (difference > 0) {
		  roundedBookings[0] = {
			...roundedBookings[0],
			price: roundedBookings[0].price + difference,
		  };
		}
	  
		return {
		  ...body, 
		  bookings: roundedBookings,
		};
	  };

	  const postBooking = async () => {
		setLoading(true);
		try {
		  if (!infoPackage) {
			setErrorMessage("Pacote de informações inválido.");
			setShowError(true);
			return; 
		  }
		  const bodyWithRoundedPrices = roundPricesDown(infoPackage.body, infoPackage.info?.packagePrice);
		  if (user && !user.isAnonymous && me?.name && validateAdvancedBilling(checkoutInformation)) {
			return await bookingTransactionService.postBookingTransaction({...bodyWithRoundedPrices, is_cancellation_policy: validateAdvancedBilling(checkoutInformation)});
		  } else {
			await bookingTransactionService.postBookingTransaction(bodyWithRoundedPrices);
			setOpen(true);
		  }
		} catch (error) {
		  setErrorMessage(error?.message || "Verifique sua conexão e tente novamente");
		  setShowError(true);
		} finally {
		  setLoading(false); 
		}
	  };

	const nextMyAppointments = () => {
		props.history.push(`/business/${business?.id}`, { view: "reserves" });
	};

	const nextReserves = () => {
		props.history.push(`/reservas/${business?.id}`, { view: "packages" });
	};

	const openDialogConfirmation = (data) => {
	       setOpen(data);
	}

	useEffect(() => {
		setLoading(true);
		if (infoPackage?.body?.bookings) {
			const bookingPrice = infoPackage?.body?.bookings.reduce((total, booking) => total + booking.price, 0);

			checkoutServices
				.fetchCancellationPolicyScreen(business?.id)
				.then(({ data }) => {
					let format = {
						total_value_for_services: bookingPrice,
						total_discounts: 0,
						anticipation_payment: bookingPrice,
						amount: bookingPrice,
						cancellation_policy: data?.cancellation_policy ?? null,
					};
					setCheckoutInformation(format);
					businessService.getContract(business.id).then(({ data }) => {
						setContract(data);
						setLoading(false);
					});
				})
				.catch((err) => {
					setLoading(false);
				});
		}
	}, []);

	useEffect(() => {
		if (cardData || pix) {
			dialog
				.withContent(DialogPayConfirmPackage)
				.withProps({
					cardData,
					pix,
					checkoutInformation,
					currency,
					payload: business?.id,
					postBooking,
					setPixCode,
					infoPackage,
					business,
					openDialogConfirmation,
					showUpdateInfo: () => setShowUpdateInfo(!showUpdateInfo),
					showError: (msg) => {
						setErrorMessage(msg);
						setShowError(true);
					},
				})
				.show();
		}
	}, [cardData, pix]);

	useEffect(() => {
		if (showUpdateInfo) {
			if (pix) {
				dialog.withContent(DialogPix).withProps({ setPix }).show();
			} else {
				dialog
					.withContent(DialogAddCard)
					.withProps({
						setCardData,
					})
					.show();
			}
			setShowUpdateInfo(false);
		}
	}, [showUpdateInfo]);

	useEffect(() => {
		if (showError) {
			dialog.withMessage(errorMessage).show();
		}
	}, [showError]);

	useEffect(() => {
		if (pixCode) {
			dialog
				.withContent(dialogPayCod)
				.withProps({
					pixCode,
					businessId: business.id,
				})
				.show();
		}
	}, [pixCode]);

	return (
		<>
			<TitleContainer handleClick={() => props.history.goBack()} footer={footer()} loading={loading}>
				<SEO business={business} page="CheckoutPage" />
				<div>
					<Fragment>
						<div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
							<div style={{ display: "flex", alignItems: "center" }}>
								<div style={{ marginRight: "8px" }}>
									<Avatar className={classes.image} alt={business.name} src={business.thumbnailUrl} />
								</div>
								<div>
									<Typography className={classes.professionalTitle}>&nbsp;{business.name}</Typography>
								</div>
							</div>
							<div style={{ alignSelf: "center" }}></div>
						</div>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div style={{ display: "flex", alignItems: "center" }}>
								<div style={{}}>
									<Typography className={classes.serviceTitle} style={{ fontSize: "20px" }}>
										{infoPackage?.info?.packageName}
									</Typography>
								</div>
							</div>
							<div style={{ alignSelf: "center" }}>
								<Typography className={classes.price}>
									R$ {Utils.moneyMask((infoPackage?.body?.bookings[0]?.price * infoPackage?.info?.quantity) / 100)}
								</Typography>
							</div>
						</div>
						<div>
							<div>
								<Typography className={classes.serviceTitle}>{t("Services included in the package")}:</Typography>
							</div>
							<div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
								<div>
									<Typography translate="no" className={classes.duration}>
										{infoPackage?.info?.quantity} {infoPackage?.info?.serviceName}
									</Typography>
								</div>
								<div>
									<Typography className={classes.duration}>
										<img src={timeIcon} alt="Horário" />
										<span translate="no" style={{ marginLeft: "2px" }}>
											{infoPackage?.info?.duration}m
										</span>
									</Typography>
								</div>
							</div>
						</div>
					</Fragment>
					<Divider />
					<div style={{ padding: "20px 0" }}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<Typography className={classes.priceDetailBlue}>{t("Balance payable")}:</Typography>
							<Typography className={classes.price}>
								{Utils.getPostPrice((infoPackage?.body?.bookings[0]?.price * infoPackage?.info?.quantity), currency)}
							</Typography>
						</div>
					</div>
				</div>
				<ConfirmationPaymentPackage
					infoPackage={infoPackage} 
					open={open} 
					business={business} 
					nextMyAppointments={nextMyAppointments} 
					nextReserves={nextReserves}
				/>
			</TitleContainer>
		</>
	);
};

export default withRouter(CheckoutPackage);
