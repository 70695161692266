import DayJsUtils from "@date-io/dayjs";
import { CssBaseline, Paper, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ClearCache from "react-clear-cache";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import MuiStylesApp from "~/components/app/MuiStylesApp";
import MuiThemeApp from "~/components/app/MuiThemeApp";
import dayjs from "~/config/dayjs";
import authService from "~/services/authService";
import Routers from "~/views/app/routers/Routers";
import { Firebase } from "~/services/firebase";
import { mixpanelInitialize } from "~/utils/mixpanel";
import authAction from "~/store/actions/authAction";
import { setMe } from "~/store/actions/meAction";
import "./App.css";

const App = () => {
	const dispatch = useDispatch();
	const classes = MuiStylesApp();

	const onAuthStateChanged = (u) => {
		if (u) {
			setMe()(dispatch);
		}
	};

	useEffect(()=>{
		const unsubscribe = Firebase.onAuthStateChanged(onAuthStateChanged);
		return () => unsubscribe();
	  }, []);

	useEffect(() => {
		return Firebase.onIdTokenChanged(async (user) => {
			if (!user) {
				authService.authenticateAnonymousUser();
			} else {
				dispatch(authAction.setUser(user));
			}
		});
	}, [dispatch]);

	useEffect(() => {
		mixpanelInitialize();
		const handle = setInterval(async () => {
			const user = Firebase.currentUser;
			if (user) await user.getIdToken(true);
		}, 10 * 60 * 1000);

		(function (c, l, a, r, i, t, y) {
			c[a] =
				c[a] ||
				function () {
					(c[a].q = c[a].q || []).push(arguments);
				};
			t = l.createElement(r);
			t.async = 1;
			t.src = "https://www.clarity.ms/tag/" + i;
			y = l.getElementsByTagName(r)[0];
			y.parentNode.insertBefore(t, y);
		})(window, document, "clarity", "script", process.env.REACT_APP_CLARITY_ID);

		return () => clearInterval(handle);
	}, []);

	return (
		<ClearCache>
			{({ isLatestVersion, emptyCacheStorage }) => {
				if (!isLatestVersion) {
					emptyCacheStorage();
				}
				return (
					<ThemeProvider theme={MuiThemeApp}>
						<CssBaseline />
						<Paper className={classes.mainPaper}>
							<MuiPickersUtilsProvider libInstance={dayjs} utils={DayJsUtils}>
								<Routers />
							</MuiPickersUtilsProvider>
						</Paper>
					</ThemeProvider>
				);
			}}
		</ClearCache>
	);
};

export default App;
