import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import ErrorDialog from "~/components/common/dialog/index";
import FormError from "~/components/common/formError";
import { TextFieldComponent } from "~/components/common/formItem";
import TitleContainer from "~/components/common/titleContainer/TitleContainer";
import { Firebase } from "~/services/firebase";
import useStyles from "./styles";
import cancellationPolicyServiceAction from "~/store/actions/cancellationPolicyActions";
import { useTranslation } from "react-i18next";
import userUpdateService from "~/services/userUpdateService";
import checkoutServices from "~/services/checkoutServices";
import { DialogBuilder } from "~/utils/DialogProvider";

const INITIAL_MESSAGE = "Ocorreu um erro inesperado! Refaça as etapas do login.";

function PersonalInformationComponent(props) {
	const classes = useStyles();
	const { t } = useTranslation();

	const dialog = useContext(DialogBuilder);

	const { user } = useSelector((store) => store.auth);

	const [fullName, setFullName] = useState("");
	const [email, setEmail] = useState("");

	const [loading, setLoading] = useState(false);

	const { goBackRoute, goBackRouteProps } = props.location.state;

	const onClickGoBack = () => {
		props.history.goBack();
	};

	const next = () => {
		props.history.push(goBackRoute, { ...goBackRouteProps });
	};

	const footer = () => {
		return (
			<>
				<Grid item container alignContent="center" justify="center">
					<div className={classes.divMessage}>
						<Typography className={classes.message}>
							<strong>
								Ao avançar, você está de acordo com os nossos <label className={classes.termos}>Termos de uso</label>.
							</strong>
						</Typography>
					</div>
				</Grid>
				<Grid item container style={{ paddingTop: 10, justifyContent: "center" }}>
					<Button
						className={classes.button}
						onClick={() => {
							submitForm();
						}}>
						{t("go")}
					</Button>
				</Grid>
			</>
		);
	};

	const submitForm = async () => {
		setLoading(true);
		const regex = /[0-9]/;

		if (!fullName) {
			setLoading(false);
			dialog.withMessage("Preencha o campo de Nome!").show();
			return;
		}
		if (regex.test(fullName)) {
			setLoading(false);
			dialog.withMessage("Preencha o campo de Nome apenas com letras!").show();
			return;
		}
		if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.trim())) {
			setLoading(false);
			dialog.withMessage("O Email inserido é inválido!").show();
			return;
		}

		localStorage.setItem("@prit-web/userEmail", email.trim());
		localStorage.setItem("@prit-web/userName", fullName);

		await userUpdateService
			.pachUserUpdate(localStorage.getItem("@prit-web/userId"), {
				name: fullName,
				email: email.trim(),
			})
			.then(() => {
				checkoutServices
					.me()
					.then((res) => {
						if (res?.data?.id) {
							localStorage.setItem("@prit-web/meData", JSON.stringify(res?.data));
							localStorage.setItem("@prit-web/userId", res?.data?.id);
						}
						if (!res?.data?.name) {
							setLoading(false);
							dialog.withMessage("Houve um erro ao cadastrar seus dados, por favor, tente novamente!").show();
							return;
						}
						next();
						setLoading(false);
					})
					.catch(() => {
						setLoading(false);
						dialog.withMessage("Houve um erro ao cadastrar seus dados, por favor, tente novamente!").show();
					});
			})
			.catch((err) => {
				setLoading(false);
				dialog.withMessage("Houve um erro ao cadastrar seus dados, por favor, tente novamente!").show();
			});
	};

	return (
		<TitleContainer
			handleClick={onClickGoBack}
			headerStyle={{ boxShadow: "none", paddingBottom: "0" }}
			loading={loading}
			footer={footer(submitForm)}>
			<Grid container direction="column" spacing={3} style={{ width: "auto" }}>
				<Grid item xs={10}>
					<Typography className={classes.title}>
						<strong>{t("inform your personal data")}</strong>
					</Typography>
				</Grid>

				<div style={{ paddingLeft: 20, paddingTop: 30, width: "inherit" }}>
					<div>
						<TextField
							className={classes.field}
							name="fullName"
							placeholder="Nome completo"
							onChange={(e) => {
								setFullName(e.target.value);
							}}
						/>
					</div>
					<div>
						<TextField
							className={classes.field}
							placeholder="E-mail"
							name="email"
							onChange={(e) => {
								setEmail(e.target.value);
							}}
							onKeyPress={(event) => {
								if (event.key === "Enter") {
									submitForm();
								}
							}}
						/>
					</div>
				</div>
			</Grid>
		</TitleContainer>
	);
}

export default withRouter(PersonalInformationComponent);
