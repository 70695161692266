import { Avatar, Typography } from "@material-ui/core";
import timeIcon from "assets/icons/group-4.png";
import clsx from "clsx";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import MuiStylesBusinessDiv from "./MuiStylesBusinessDiv";

const BusinessDiv = (props) => {
	const { service, selectedService } = props;

	const { business } = useSelector((store) => store.business);
	const classes = MuiStylesBusinessDiv();
	const sectors = JSON.parse(localStorage.getItem("@prit-web/sectors"));

	const sector = useMemo(() => {
		let name = "";
		sectors.forEach((item) => {
			const sector_categories = item.categories.map((item) => item.id);
			const business_categories = business.categories.map((item) => item.id);

			if (JSON.stringify(sector_categories) === JSON.stringify(business_categories)) name = item.name;
		});
		return name;
	}, [sectors, business]);

	const convert = (minutes) => {
		const hours = Math.floor(minutes / 60);
		const min = minutes % 60;
		const textHours = `${hours}`.slice(-2);
		const textMin = `00${min}`.slice(-2);

		return textHours === "0" ? ` ${textMin}m` : `${textHours}h ${textMin}m`;
	};

	const currency = business?.currency;

	return (
		<div style={{ display: "flex", flexDirection: "row" }}>
			<div style={{ marginRight: "15px", marginBottom: "15px" }}>
				<Avatar
					variant="rounded"
					className={classes.avatar}
					src={business?.thumbnailUrl ? business.thumbnailUrl : ""}
				/>
			</div>
			<div>
				<Typography className={clsx({ [classes.title]: true })}>{business?.name ? business.name : ""}</Typography>
				<Typography className={clsx({ [classes.subtitle]: true })}>{sector}</Typography>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Typography className={clsx({ [classes.price]: true })}>
						{`${
							service?.pricingType && /o mostrar/gi.test(service?.pricingType?.name)
								? "Sob consulta"
								: `${service?.pricingType?.name !== "Fixo" ? service?.pricingType?.name : ""} ${
										service?.pricingType?.name !== "Gratuito" && service.services[selectedService]
											? parseFloat(service.services[selectedService].price / 100).toLocaleString("pt-BR", {
													style: "currency",
													currency: currency,
											  })
											: ""
								  }`
						}`}
					</Typography>
					<p translate="no" className={classes.time}>
						{" - "}
						<img src={timeIcon} alt="Horário" />
						{service?.duration ? convert(service.duration) : "0m"}
					</p>
				</div>
			</div>
		</div>
	);
};

export default BusinessDiv;
