import { Dialog, Divider, Switch, Typography } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import ModalReservations from "~/components/modalBookingScreen/ModalReservations";
import dayjs from "~/config/dayjs";
import bookingTransactionService, { getBusinessBookingTransaction } from "~/services/bookingTransactionService";
import packagesServices from "~/services/packagesServices";
import paymentListServices from "~/services/paymentListServices";
import { DialogBuilder } from "~/utils/DialogProvider";
import { googleSignIn, googleSignOut, isSignedOnGoogle } from "~/utils/GoogleCalendar/GoogleCalendar";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";

import CouponImage from "assets/icons/cupom-top.png";

import DialogText from "../common/dialog/dialogSucess";
import SpinnerLoading from "../common/spinnerLoading/SpinnerLoading";
import MuiStylesCardReservations from "./MuiStylesCardReservations";

const CardReservations = (props) => {
	const classes = MuiStylesCardReservations();

	const typeView = props.location?.state?.view;
	const { bookingTransactionId } = useParams();
	const { id } = useParams();
	const { business } = useSelector((store) => store.business);
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showLoadMore, setShowLoadMore] = useState(true);
	const [showLoadMorePackages, setShowLoadMorePackages] = useState(false);
	const [selectedReservation, setSelectedReservation] = useState({});
	const [showReservations, setShowReservations] = useState(typeView === "reserves" ? true : (typeView === "packages" ? false : true));
	const [openPixConfirmation, setOpenPixConfirmation] = useState(false);
	const [reservations, setReservations] = useState([]);
	const [paymentList, setPaymentList] = useState([]);
	const [page, setPage] = useState(1);
	const [pagePackage, setPagePackage] = useState(1);
	const [validationEnd, setValidationEnd] = useState(true);
	const [googleCalendarSync, setGoogleCalendarSync] = useState(false);
	const currency = business?.currency || "BRL";
	const [pixConfirmation, setPixConfirmation] = useState(
		new URLSearchParams(props.location.search).get("pixConfirmation")
	);
	const userId = localStorage.getItem("@prit-web/userId");
	const [packages, setPackages] = useState([]);

	const dialog = useContext(DialogBuilder);

	const bookingStatusTypes = {
		active: t("active"),
		inactive: t("inactive"),
		cancelled: t("cancelled"),
		completed: t("completed"),
		pending_accept: t("pending_accept"),
		refuse: t("refuse"),
		accept: t("accept"),
		waiting: t("waiting"),
	};

	const cancellationReasonTypes = {
		client: t("client"),
		business: t("business"),
		lack_payment: t("lack_payment"),
	};

	const status = (selectedReservation) => {
		if (selectedReservation.status === "cancelled") {
			return cancellationReasonTypes[selectedReservation.cancellationReason];
		}
		if (selectedReservation.status === "active" && selectedReservation.confirmReservation) {
			return t("Confirmed");
		}
		return bookingStatusTypes[selectedReservation.status];
	};

	const fetchReservations = (fetchPage, perPage) => {
		setLoading(true);
		if (!userId) {
			setLoading(false);
			localStorage.setItem("goBackRoute", "/reservas/" + business.id);
			props.history.push(`/login`, { goBackRoute: "/reservas/" + business.id, goBackRouteProps: props.location.state });
			return;
		}
		getBusinessBookingTransaction(userId, business.id, fetchPage, perPage)
			.then((res) => {
				let bookingTransaction = res.data;
				let services = [];
				bookingTransaction.forEach((transaction) => {
					transaction.bookings.forEach((booking) => {
						let service = {
							...booking.service,
							bookingId: booking.id,
							bookingTransactionId: transaction.id,
							business: booking.business,
							professional: booking.professional.name,
							avatarUrlProfessional: booking.professional.avatarUrl,
							price: booking.price,
							pricingType: booking.pricingType,
							startTime: booking.startTime,
							createdAt: booking.createdAt,
							endTime: booking.endTime,
							status: booking.status,
							confirmReservation: booking.confirmReservation,
							cancellationReason: booking.cancellationReason,
							total: transaction.total,
							coupon: transaction.couponId,
							professionalId: booking.professional.id,
							serviceId: booking.service.id,
							businessId: booking.business.id,
							answer: booking.bookingAnswers.length > 0 ? booking.bookingAnswers[0] : "",
						};
						services.push(service);
					});
				});
				if (services.length < perPage) {
					setShowLoadMore(false);
				}
				setReservations([...reservations, ...services]);
				getPaymentLists(services.map((e) => e.bookingTransactionId));
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				if (err.response.status === 401 || err.response.status === 403) {
					localStorage.setItem("goBackRoute", "/reservas/" + business.id);
					props.history.push(`/login`, {
						goBackRoute: "/reservas/" + business.id,
						goBackRouteProps: props.location.state,
					});
				}
			});
	};

	const getPaymentLists = (ids) => {
		paymentListServices
			.postPaymentListServices({
				booking_transaction_ids: ids,
			})
			.then(({ data }) => {
				setPaymentList([...paymentList, ...data]);
			});
	};

	useEffect(() => {
		if (business && business.id !== id) {
			localStorage.setItem("goBackRoute", props.location.pathname);
			props.history.push(`/business/${id}`, props.location.state);
			return;
		}
		if (business !== null && page === 1 && !loading && validationEnd) {
			fetchReservations(1, 10);
			setValidationEnd(false);
		}
	}, [business, page]);

	const handleClickOpen = (item) => {
		setSelectedReservation(item);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (reservations?.length > 0 && pixConfirmation) {
			setOpenPixConfirmation(true);
		}
	}, [reservations]);

	const handleGoogleCalendarSyncSucess = () => {
		setGoogleCalendarSync(true);
	};

	const handleGoogleAgendaSave = () => {
		if (!googleCalendarSync) {
			googleSignIn(handleGoogleCalendarSyncSucess);
		} else {
			googleSignOut();
			setGoogleCalendarSync(false);
		}
	};

	useEffect(() => {
		isSignedOnGoogle(handleGoogleCalendarSyncSucess);
	}, []);

	const loadDetails = async (bookingTransactionId) => {
		bookingTransactionService
			.getOneBookingTransaction(bookingTransactionId)
			.then((res) => {
				let transaction = res.data;
				let services = [];
				transaction.bookings.forEach((booking) => {
					let service = {
						...booking.service,
						bookingId: booking.id,
						bookingTransactionId: transaction.id,
						business: booking.business,
						professional: booking.professional.name,
						avatarUrlProfessional: booking.professional.avatarUrl,
						price: booking.price,
						pricingType: booking.pricingType,
						startTime: booking.startTime,
						createdAt: booking.createdAt,
						endTime: booking.endTime,
						status: booking.status,
						confirmReservation: booking.confirmReservation,
						cancellationReason: booking.cancellationReason,
						total: transaction.total,
						coupon: transaction.couponId,
						professionalId: booking.professional.id,
						serviceId: booking.service.id,
						businessId: booking.business.id,
						answer: booking.bookingAnswers.length > 0 ? booking.bookingAnswers[0] : "",
					};
					services.push(service);
				});
				handleClickOpen(services[0]);
			})
			.catch((err) => {
				setLoading(false);
				if (err?.response?.status == 401) {
					localStorage.setItem("goBackRoute", window.location.pathname);
					props.history.push(`/login`, {
						goBackRoute: window.location.pathname,
						goBackRouteProps: props.location.state,
					});
				} else {
					dialog
						.withMessage(`${err?.response?.data?.message || "Ocorreu um erro inesperado, "} Tente novamente!`)
						.show();
				}
			});
	};

	const fetchPackagesScheduled = (fetchPage, perPage) => {
		try {
		  setLoading(true);
		  packagesServices.packageBookings(business?.id, fetchPage, perPage).then((res) => {
			const totalPage = parseInt(res.headers["x-total-count"]); 
	  
			setPackages(prevPackages => [...prevPackages, ...res.data]);
			getPaymentLists(res.data.map((e) => e.bookingTransactionId));
			setLoading(false);
	  
			if (perPage !== 0 && perPage === totalPage) {
			  setShowLoadMorePackages(true); 
			} else {
				setShowLoadMorePackages(false); 
			}
		  }).catch(error => {
			  setLoading(false);
			  console.error("Erro ao buscar pacotes:", error);
		  });
		} catch (error) {
		  setLoading(false);
		  console.error("Erro inesperado:", error);
		}
	  };

	useEffect(() => {
		if (bookingTransactionId) {
			loadDetails(bookingTransactionId);
		}
	}, []);

	useEffect(() => {
		setReservations([]);
		setPackages([]);
		
		if (!showReservations && !loading) {
			setPagePackage(1);
			fetchPackagesScheduled(1, 10);
		} else {
			if (business !== null && !loading) {
				setPage(1);
				fetchReservations(1, 10);
				setValidationEnd(false);
			}
		}

	},[showReservations])

	const bookingPayment = (id) => {
		let payment = paymentList.filter((e) => e.booking_transaction_id === id);
		return payment.length > 0 ? payment[0].total_transactions : 0;
	};

	const bookingDiscount = (id) => {
		let payment = paymentList.filter((e) => e.booking_transaction_id === id);
		return payment.length > 0 ? payment[0].total_discount : 0;
	};

	const bookingPaymentRefund = (id) => {
		let payment = paymentList.filter((e) => e.booking_transaction_id === id);
		return payment.length > 0 ? payment[0].total_refunded : null;
	};

	return (
		<>
			<script src="https://apis.google.com/js/api.js" type="text/javascript"></script>
			<Divider />
			<div style={{ display: "flex", marginLeft: "22px" }}>
				<Switch
					checked={googleCalendarSync}
					color="primary"
					onChange={handleGoogleAgendaSave}
					inputProps={{ "aria-label": "controlled" }}
				/>
				<Typography style={{ padding: "9px" }}>
					{googleCalendarSync ? t("Synchronized with Google Calendar") : t("Sync with Google Calendar")}
				</Typography>
			</div>
			<Divider />
			<SpinnerLoading loading={loading} />
			<div style={{ display: "flex", justifyContent: "space-around", margin: "20px 0" }}>
				<button
					onClick={() => setShowReservations(true)}
					style={{ marginRight: "10px", cursor: "pointer" }}
					className={`${classes.buttonTab} ${showReservations ? "active" : ""}`}
					isActive={showReservations}>
					{t("Reservations")}
				</button>
				<button
					onClick={() => setShowReservations(false)}
					style={{ cursor: "pointer" }}
					className={`${classes.buttonTab} ${!showReservations ? "active" : ""}`}
					isActive={!showReservations}>
					{t("Packages")}
				</button>
			</div>

			{showReservations ? (
				<>
					{reservations?.length > 0 ? (
						<div>
							{reservations.map((item, index) => {
								const date = item.startTime ? dayjs(item.startTime) : null;
								const dateEnd = item.endTime ? dayjs(item.endTime) : null;
								return (
									<div key={`reservations-${index}`}>
										<div variant="outlined" color="primary">
											<div key={`reservations-${index}`} style={{ display: "flex", flexDirection: "row" }}>
												<div
													className={classes.rectangle}
													onClick={() => {
														if (date && dateEnd) {
															handleClickOpen(item);
														} else {
															props.history.push(`/selecionar-data/${business.id}`, {
																services: [item],
																bookingId: item.bookingId,
																reservationUpdate: true,
																reschedule: item,
															});
														}
													}}>
													<div style={{ display: "flex" }}>
														<Typography className={classes.date}>
															{(date && dateEnd && date.format("DD/MM/YYYY")) || t("To be scheduled")}
														</Typography>
														{item?.coupon && (
															<img alt="coupon" src={CouponImage} style={{ width: "10px", height: "16px" }} />
														)}
													</div>

													<Divider />
													<Typography className={classes.hours}>
														{(date && dateEnd && date.format("HH:mm") + "-" + dateEnd.format("HH:mm")) || t("To be scheduled")}
													</Typography>
													<Typography className={classes.status}>{status(item)}</Typography>
													<Typography translate="no" className={classes.nameService}>{item.name}</Typography>
													<Typography className={classes.value}>
														{`${
															item.pricingType && /o mostrar/gi.test(item.pricingType.name)
																? "Sob consulta"
																: `${item.pricingType.name !== "Fixo" ? item.pricingType.name : ""} ${
																		item.pricingType.name !== "Gratuito"
																			? parseFloat(
																					(item.price - bookingDiscount(item.bookingTransactionId)) / 100
																			  ).toLocaleString("pt-BR", {
																					style: "currency",
																					currency: currency,
																			  })
																			: ""
																  }`
														}`}
													</Typography>
													<Divider />
													{item.status !== "cancelled" && (
														<>
															<Typography className={classes.payment}>{t("Payment made")}</Typography>
															<Typography className={classes.total}>{`${parseFloat(
																!_.isNil(bookingPayment(item.bookingTransactionId))
																	? bookingPayment(item.bookingTransactionId) / 100
																	: 0
															).toLocaleString("pt-BR", {
																style: "currency",
																currency: currency,
															})}`}</Typography>
														</>
													)}
													{item.status === "cancelled" && (
														<>
															<Typography className={classes.payment}>
																{bookingPaymentRefund(item.bookingTransactionId)
																	? t("Reversed payment")
																	: t("Waiting reversed payment")}
															</Typography>
															{bookingPaymentRefund(item.bookingTransactionId) && (
																<Typography className={classes.total}>{`${parseFloat(
																	!_.isNil(bookingPaymentRefund(item.bookingTransactionId))
																		? bookingPaymentRefund(item.bookingTransactionId) / 100
																		: 0
																).toLocaleString("pt-BR", {
																	style: "currency",
																	currency: currency,
																})}`}</Typography>
															)}
														</>
													)}
												</div>
											</div>
										</div>
									</div>
								);
							})}
							{showLoadMore && (
								<div
									style={{ color: "#474747", display: "flex", justifyContent: "center", padding: "20px" }}
									onClick={() => {
										fetchReservations(page + 1, 10);
										setPage(page + 1);
									}}>
									<AddCircle></AddCircle>
									<Typography>{t("Load more")}</Typography>
								</div>
							)}
						</div>
					) : (
						!loading && <div className={classes.verificationText}>{t("No reservations scheduled")}</div>
					)}
				</>
			) : (
				<>
					{packages && packages.length > 0 ? (
						<>
							{packages.map((item, index) => {
								const date = item.startTime ? dayjs(item.startTime) : null;
								const dateEnd = item.endTime ? dayjs(item.endTime) : null;

								return (
									<>
										<div key={`reservations-${index}`}>
											<div variant="outlined" color="primary">
												<div key={`reservations-${index}`} style={{ display: "flex", flexDirection: "row" }}>
													<div
														className={classes.rectangle}
														onClick={() => {
															if (date && dateEnd) {
																handleClickOpen(item);
															} else {
																props.history.push(`/selecionar-data/${business.id}`, {
																	services: [{...item?.service, price: item?.price}],
																	bookingId: item?.id,
																	reservationUpdate: true,
																	isPackage: true,
																});
															}
														}}>
														<div style={{ display: "flex" }}>
															<Typography className={classes.date}>
																{(date && dateEnd && date.format("DD/MM/YYYY")) || t("To be scheduled")}
															</Typography>
														</div>

														<Divider />
														<Typography className={classes.hours}>
															{(date && dateEnd && date.format("HH:mm") + "-" + dateEnd.format("HH:mm")) || t("To be scheduled")}
														</Typography>
														<Typography className={classes.status}></Typography>
														<Typography translate="no" className={classes.nameService}>{item?.service?.name}</Typography>
														<Typography className={classes.value}>
															{`${
																item.pricingType && /o mostrar/gi.test(item.pricingType.name)
																	? "Sob consulta"
																	: `${item.pricingType.name !== "Fixo" ? item.pricingType.name : ""} ${
																			item.pricingType.name !== "Gratuito"
																				? parseFloat(
																						(item?.price - bookingDiscount(item.bookingTransactionId)) / 100
																				  ).toLocaleString("pt-BR", {
																						style: "currency",
																						currency: currency,
																				  })
																				: ""
																	  }`
															}`}
														</Typography>
														<Divider />
														{item.status !== "cancelled" && (
															<>
																<Typography className={classes.payment}>{t("Payment made")}</Typography>
																<Typography className={classes.total}>{`${parseFloat(
																	!_.isNil(bookingPayment(item.bookingTransactionId))
																		? item?.price / 100
																		: 0
																).toLocaleString("pt-BR", {
																	style: "currency",
																	currency: currency,
																})}`}</Typography>
															</>
														)}
														{item.status === "cancelled" && (
															<>
																<Typography className={classes.payment}>
																	{bookingPaymentRefund(item.bookingTransactionId)
																		? t("Reversed payment")
																		: t("Waiting reversed payment")}
																</Typography>
																{bookingPaymentRefund(item.bookingTransactionId) && (
																	<Typography className={classes.total}>{`${parseFloat(
																		!_.isNil(bookingPaymentRefund(item.bookingTransactionId))
																			? item?.price / 100
																			: 0
																	).toLocaleString("pt-BR", {
																		style: "currency",
																		currency: currency,
																	})}`}</Typography>
																)}
															</>
														)}
													</div>
												</div>
											</div>
										</div>
									</>
								);
							})}
							{showLoadMorePackages && (
								<div
									style={{ color: "#474747", display: "flex", justifyContent: "center", padding: "20px" }}
									onClick={() => {
										fetchPackagesScheduled(pagePackage + 1, 10);
										setPagePackage(pagePackage + 1);
									}}>
									<AddCircle></AddCircle>
									<Typography>{t("Load more")}</Typography>
								</div>
							)}
						</>
					) : (
						<div className={classes.verificationText}>{t("No packages scheduled")}</div>
					)}
				</>
			)}

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				PaperProps={{
					style: { borderRadius: 20 },
				}}>
				{selectedReservation && business && (
					<ModalReservations
						serviceId={selectedReservation.serviceId}
						open={handleClickOpen}
						onClose={handleClose}
						business={business}
						bookingId={selectedReservation.bookingId}
						bookingTransactionId={selectedReservation.bookingTransactionId}
						date={dayjs(selectedReservation.startTime)}
						dateEnd={dayjs(selectedReservation.endTime)}
						serviceName={selectedReservation.name}
						price={selectedReservation.price}
						professional={selectedReservation.professional}
						professionalId={selectedReservation.professionalId}
						props={props}
						idGfi={selectedReservation.id}
						categoryIds={selectedReservation.featureItems}
						status={selectedReservation.status}
						confirmReservation={selectedReservation.confirmReservation}
						startTime={selectedReservation.startTime}
						createdAt={selectedReservation.createdAt}
						pricingType={selectedReservation.pricingType}
						priceWithDiscount={selectedReservation.price}
					/>
				)}
			</Dialog>
			<DialogText
				onOpen={openPixConfirmation}
				message="Pagamento pix confirmado!"
				title=" "
				pritman={true}
				onClose={async () => {
					setOpenPixConfirmation(false);
					loadDetails(pixConfirmation);
					setPixConfirmation(null);
				}}></DialogText>
		</>
	);
};

export default withRouter(CardReservations);
