import { Avatar, Divider, Typography } from "@material-ui/core";
import timeIcon from "assets/icons/group-4.png";
import React from "react";
import { useSelector } from "react-redux";
import MuiStylesProfessionalDiv from "~/components/common/professionalDiv/MuiStylesProfessionalDiv";

const ProfessionalDiv = (props) => {
	const { index, professional, isPackage, priceServicePackage } = props;

	const { business } = useSelector((store) => store.business);

	const classes = MuiStylesProfessionalDiv();

	const margin = index === 0 ? "4px 0 20px" : "20px 0";

	const currency = business?.currency;

	const priceService = (priceServicePackage) => {
		if (isPackage) {
			return priceServicePackage;
		}
		return professional?.price;
	}

	const convert = (minutes) => {
		const hours = Math.floor(minutes / 60);
		const min = minutes % 60;
		const textHours = `${hours}`.slice(-2);
		const textMin = `00${min}`.slice(-2);

		return textHours === "0" ? ` ${textMin}m` : `${textHours}h ${textMin}m`;
	};

	return (
		<>
			{props.professionalView ? (
				<div key={`professional-${index}`} style={{ display: "flex", flexDirection: "row" }}>
					<div style={{ marginRight: "15px" }}>
						<Avatar alt={professional.name} src={professional.avatarUrl} />
					</div>
					<div>
						<Typography style={{ fontSize: "15px", fontWeight: "500" }}>{professional.name ?? "Sem nome"}</Typography>
						{/* <Typography className={clsx({ [classes.subtitle]: true })}>{sector}</Typography> */}
						<div style={{ display: "flex", flexDirection: "row" }}>
							<p translate="no" className={classes.time}>
								<img src={timeIcon} alt="Horário" />
								{convert(professional?.duration)}
								{" - "}
							</p>
							<Typography className={classes.price}>
								{`${
									(professional?.pricing_type || professional?.pricingType) &&
									/o mostrar/gi.test(professional?.pricing_type || professional?.pricingType)
										? "Sob consulta"
										: `${
											(professional?.pricing_type || professional?.pricingType)
											!== "Fixo"
													? (professional?.pricing_type || professional?.pricingType)
													: ""
										  } ${
											(professional?.pricing_type || professional?.pricingType) !== "Gratuito"
													? parseFloat(priceService(priceServicePackage) / 100).toLocaleString("pt-BR", {
															style: "currency",
															currency: currency,
													  })
													: ""
										  }`
								}`}
							</Typography>
						</div>
					</div>
				</div>
			) : (
				<>
					<div key={`professional-${index}`} style={{ display: "flex", flexDirection: "row", margin }}>
						<Avatar alt={professional.name} src={professional.avatarUrl} />
						<div style={{ marginLeft: "20px" }}>
							<Typography style={{ fontSize: "16px", fontWeight: "500" }}>{professional.name}</Typography>
						</div>
					</div>
					<Divider style={{ color: "#eeeeee", backgroundColor: "#eeeeee" }} />
				</>
			)}
		</>
	);
};

export default ProfessionalDiv;
