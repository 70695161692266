import { IconButton, Typography } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const TimeOptionsDiv = ({ options, onSelect, selected, professional, professionalIndex, service, timeZone, professionals }) => {
	const [startIndex, setStartIndex] = useState(0);
	const { t } = useTranslation();

	const totalItems = professionals
        ? professionals.reduce((total, p) => total + (p.schedule?.length || 0), 0)
        : options.length;

	useEffect(()=>{
		setStartIndex(0);
	},[])

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "start",
				width: "100%",
				gap: "2%",
			}}>
			{!(startIndex == 0) && (
				<IconButton
					onClick={() => {
						if (startIndex > 0) {
							setStartIndex(startIndex - 4);
						}
					}}
					style={{
						color: "darkgray",
						backgroundColor: "#dddddd",
						borderRadius: "5px",
						width: "30px",
						height: "30px",
						display: "flex",
						justifyContent: "center",
					}}>
					<ArrowBackIos style={{ position: "relative", left: "3px", color: "#333333", padding: "4px" }} />
				</IconButton>
			)}

			{professionals && professionals.length > 0 ? (
				professionals
				.filter(p => p.schedule && p.schedule.length > 0) 
				.map((professional) => { 
					return professional.schedule.map((scheduleItem, i) => { 
						const availability = scheduleItem[0]; 
	
						let isSelected = professional.id === selected?.professional?.id && availability?.start === selected?.time?.start;
		
						if (i >= startIndex && i < startIndex + 4) {
							return (
								<div
									onClick={() => {
										onSelect({ time: availability, professional: professional, service: service });
									}}
									style={{
										color: isSelected ? "white" : "#333333",
										backgroundColor: isSelected ? "#450067" : "#dddddd",
										borderRadius: "5px",
										alignItems: "center",
										width: "20%",
										display: "flex",
										justifyContent: "center",
										height: "30px",
									}}
									key={availability.start} 
								>
									{dayjs(availability?.start).format("HH:mm")}
								</div>
							);
						}
					});
				})
			) : (		
				options.map((item, i) => {
					
					let isSelected = professional.id === selected?.professional?.id && item[0]?.start === selected?.time?.start;
					
					if (i >= startIndex && i < startIndex + 4) {
						return (
							<div
								onClick={() => {
									onSelect({ time: item[0], professional: professional, service: service });
								}}
								style={{
									color: isSelected ? "white" : "#333333",
									backgroundColor: isSelected ? "#450067" : "#dddddd",
									borderRadius: "5px",
									alignItems: "center",
									width: "20%",
									display: "flex",
									justifyContent: "center",
									height: "30px",
								}}
								// key={item.start[0]}
							>
								{dayjs(item[0]?.start).format("HH:mm")}
							</div>
						);
					}
				})
			)}

			<IconButton
				onClick={() => {
					if (startIndex + 4 <= (totalItems)) {
						setStartIndex(startIndex + 4);
					}
				}}
				style={{
					color: "darkgray",
					backgroundColor: "#dddddd",
					borderRadius: "5px",
					width: startIndex == 0 ? "70px" : "30px",
					height: "30px",
					display: startIndex + 4 >= totalItems ? "none" : "flex",
					justifyContent: "center",
				}}>
				{startIndex == 0 && <Typography style={{ color: "#333333", paddingLeft: "4px" }}>{t("More")}</Typography>}
				<ArrowForwardIos style={{ color: "#333333", padding: "4px" }} />
			</IconButton>
		</div>
	);
};

export default TimeOptionsDiv;
