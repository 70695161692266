import { Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { DialogBuilder } from "~/utils/DialogProvider";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { returnLanguage, treatCategoriesLanguage } from "../../utils/prepareValues";
import useStyles from "./styles";

const VendorCategorieScheduling = (props) => {
	const classes = useStyles();
	const { id } = useParams();
	const dispatch = useDispatch();
	const dialog = useContext(DialogBuilder);
	const [loading, setLoading] = useState(false);

	const pet = props?.selectedPet;
	const coupon = props?.location?.state?.coupon;
	const selectCategory = props?.selectCategory;
	const business = props.business;
	const languageSelected = localStorage.getItem("@prit-web/language") || business?.language;
	const language = returnLanguage(languageSelected ?? business?.language);

	const { t } = useTranslation();
	const { user } = useSelector((store) => store.auth);

	const [categoriesList, setCategoriesList] = useState([]);
		const [selectedCategorie, setSelectedCategorie] = useState(null);

	const goToNextPage = (category) => {
		selectCategory({ categorie: category, pet: pet, coupon: coupon });
	};

	useEffect(() => {
		if (business?.categories) {
			if (business.categories.length > 1) {
				setCategoriesList(business.categories.sort((a, b) => (a?.name < b?.name ? -1 : 1)));
			} else {
				goToNextPage(business.categories[0]);
			}
		}
	}, []);

	return (
		<Fragment>
			<div>
				<Typography className={classes.levelTitle} style={{ margin: "0px 0px" }}>
					{t("Select a category")}
				</Typography>
				{categoriesList?.length > 0 && (
					<div style={{ display: "flex", justifyContent: "space-evenly", width: "auto", maxWidth: "400px" }}>
						<div
							style={{
								display: "flex",
								flexFlow: "wrap",
								maxWidth: "500px",
								justifyContent: "space-evenly",
							}}>
							{categoriesList.map((item) => {
								return (
									<div
										style={{
											margin: "20px 0",
											boxShadow: "0px 3px 5px rgba(0,0,0,0.5)",
											borderRadius: "20%",
											width: "25vw",
											height: "25vw",
											maxHeight: "100px",
											maxWidth: "100px",
										}}
										onClick={() => {
											goToNextPage(item);
											setSelectedCategorie(item);
										}}>
										{selectedCategorie?.id === item?.id && (
											<div
												style={{
													backgroundColor: "#450067",
													opacity: 0.8,
													width: "25vw",
													height: "calc(25vw*0.7)",
													maxHeight: "70px",
													maxWidth: "100px",
													display: "flex",
													justifyContent: "center",
													flex: "33%",
												}}
												className={classes.colorClickBtn}>
												<CheckCircleIcon
													style={{
														backgroundColor: "#450067",
														opacity: 0.8,
														fontSize: "25px",
														alignSelf: "center",
													}}
													className={classes.checkCategory}
												/>
											</div>
										)}
										<img src={item?.image} alt={item.name} className={classes.RectangleImage} />
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												height: "calc(25vw*0.3)",
												maxHeight: "30px",
												padding: "10%",
												marginTop: "-7%",
											}}>
											<h1 translate="no" className={classes.nameCategorieSelection}>{item?.['name' + treatCategoriesLanguage(language)]}</h1>
										</div>
									</div>
								);
							})}
							{categoriesList % 3 == 1 && (
								<div
									style={{
										margin: "20px 0",
										width: "25vw",
										height: "25vw",
										maxHeight: "100px",
										maxWidth: "100px",
									}}></div>
							)}
							{categoriesList % 3 != 0 && (
								<div
									style={{
										margin: "20px 0",
										width: "25vw",
										height: "25vw",
										maxHeight: "100px",
										maxWidth: "100px",
									}}></div>
							)}
							<div
								style={{
									margin: "20px 0",
									width: "25vw",
									height: "25vw",
									maxHeight: "100px",
									maxWidth: "100px",
								}}></div>
						</div>
					</div>
				)}
			</div>
		</Fragment>
	);
};
export default withRouter(VendorCategorieScheduling);
