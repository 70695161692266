import React, { useEffect, useState } from "react";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { Button, Divider, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const Calendar = (props) => {
	const { t } = useTranslation();

	const backgroundColor = props?.backgroundColor;
	const selectedColor = props?.color || "#450067";
	const selectedDate = props?.date ? new Date(props?.date) : new Date(Date.now());
	const [actualDate, setActualDate] = useState(selectedDate);
	const { onChange, setDate, style, daysToNotify, availableDates } = props;
	const daysOfWeek = [t("Sun"), t("Mon"), t("Tues"), t("Wed"), t("Thurs"), t("Fri"), t("Sat")];

	const convertMonth = (month) => {
		switch (month) {
			case 0:
				return t("January");
			case 1:
				return t("February");
			case 2:
				return t("March");
			case 3:
				return t("April");
			case 4:
				return t("May");
			case 5:
				return t("June");
			case 6:
				return t("July");
			case 7:
				return t("August");
			case 8:
				return t("September");
			case 9:
				return t("October");
			case 10:
				return t("November");
			case 11:
				return t("December");
		}
	};

	const convertDayOfWeek = (day) => {
		switch (day) {
			case 0:
				return t("Sunday");
			case 1:
				return t("Monday");
			case 2:
				return t("Tuesday");
			case 3:
				return t("Wednesday");
			case 4:
				return t("Thursday");
			case 5:
				return t("Friday");
			case 6:
				return t("Saturday");
		}
	};

	const getDaysOfMounth = () => {
		let days = [[], [], [], [], [], []];
		let fristDay = new Date(actualDate.getFullYear(), actualDate.getMonth(), 1);
		let lastDay = new Date(actualDate.getFullYear(), actualDate.getMonth() + 1, 0).getDate();

		let j = 0;

		for (var i = 0; i < fristDay.getDay(); i++) {
			days[j].push(null);
			if (days[j].length == 7 && j < 5) {
				j++;
			}
		}

		for (var i = 1; i <= lastDay; i++) {
			days[j].push(i);
			if (days[j].length == 7 && j < 5) {
				j++;
			}
		}

		const remainingLength = 42 - lastDay - fristDay.getDay();

		for (var i = 0; i < remainingLength; i++) {
			days[j].push(null);
			if (days[j].length == 7 && j < 5) {
				j++;
			}
		}

		return days;
	};

	const [daysOfMonth, setDaysOfMonth] = useState([]);

	const nextMonth = () => {
		if (actualDate.getMonth() == 11) {
			setActualDate(new Date(actualDate.getFullYear() + 1, 0, 1));
		} else {
			setActualDate(new Date(actualDate.getFullYear(), actualDate.getMonth() + 1, 1));
		}
	};

	const prevMonth = () => {
		if (actualDate.getMonth() == 0) {
			setActualDate(new Date(actualDate.getFullYear() - 1, 11, 1));
		} else {
			setActualDate(new Date(actualDate.getFullYear(), actualDate.getMonth() - 1, 1));
		}
	};

	useEffect(() => {
		setDaysOfMonth(getDaysOfMounth(actualDate.getMonth()));
	}, [actualDate]);

	const haveNotificationTo = (day) => {
		for (let i = 0; i < daysToNotify.length; i++) {
			let notifyDate = new Date(daysToNotify[i]);
			if (
				notifyDate.getDate() + 1 == day &&
				notifyDate.getMonth() == actualDate.getMonth() &&
				notifyDate.getFullYear() == actualDate.getFullYear()
			) {
				return true;
			}
		}
		return false;
	};

	const available = (day) => {
		if (!availableDates || !day) {
			return false;
		}
		let dateAvailable =
			actualDate.getFullYear() +
			"-" +
			(actualDate.getMonth() + 1 <= 9 ? "0" : "") +
			(actualDate.getMonth() + 1) +
			"-" +
			(day <= 9 ? "0" : "") +
			day;
		for (let i = 0; i < availableDates?.length; i++) {
			if (dateAvailable == availableDates[i]) {
				return true;
			}
		}
		return false;
	};

	return (
		<div style={style}>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<div>
					<div
						style={{
							backgroundColor: backgroundColor,
							display: "flex",
							flexDirection: "column",
						}}>
						<div
							style={{
								display: "flex",

								justifyContent: "space-between",
								alignItems: "center",
								alignSelf: "center",
							}}>
							<ArrowBackIos
								onClick={prevMonth}
								style={{
									color: selectedColor,
									width: "24px",

									border: "none",
									backgroundColor: "none",
									color: "#222222",
								}}></ArrowBackIos>

							<Typography
								style={{
									color: "#222222",
									textAlign: "center",
									fontSize: "16px",
									width: "80vw",
									maxWidth: "260px",
								}}>
								{`${convertMonth(actualDate.getMonth())} ${actualDate.getFullYear()}`}
							</Typography>

							<ArrowForwardIos
								onClick={nextMonth}
								style={{
									width: "24px",
									border: "none",
									backgroundColor: "none",

									color: "#222222",
								}}></ArrowForwardIos>
						</div>
						<div className="notranslate"
							style={{
								display: "flex",
								justifyContent: "space-between",
								margin: "10px 0",
								width: "80vw",
								maxWidth: "320px",
								alignSelf: "center",
							}}>
							{daysOfWeek.map((day) => {
								return (
									<Typography
										style={{
											color: "lightgray",
											fontSize: "16px",
										}}>
										{day}
									</Typography>
								);
							})}
						</div>
						<div style={{ height: "1px", flex: 1, backgroundColor: "lightgray" }}></div>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							margin: "10px 0",
							alignSelf: "center",
						}}>
						<div style={{ height: "200px" }}>
							{daysOfMonth.map((week) => {
								return (
									<div
										style={{
											marginTop: "10px",
											display: "flex",
											justifyContent: "space-between",

											margin: "10px 0",
											width: "80vw",
											maxWidth: "320px",
										}}>
										{week.map((day) => {
											let isAvailable = available(day);
											return (
												<>
													<Typography
														onClick={() => {
															if (day != null && isAvailable) {
																setDate(new Date(actualDate.getFullYear(), actualDate.getMonth(), day));
																if (onChange) {
																	onChange();
																}
															}
														}}
														style={{
															width: "30px",
															color:
																props?.date &&
																selectedDate.getDate() == day &&
																selectedDate.getMonth() == actualDate.getMonth() &&
																selectedDate.getFullYear() == actualDate.getFullYear()
																	? "white"
																	: !isAvailable
																	? "#cccccc"
																	: "gray",
															fontSize: "14px",
															padding: "5px 5px",
															margin: "1px 0",
															textAlign: "center",
															backgroundColor:
																props?.date &&
																selectedDate.getDate() == day &&
																selectedDate.getMonth() == actualDate.getMonth() &&
																selectedDate.getFullYear() == actualDate.getFullYear()
																	? selectedColor
																	: "transparent",
															borderRadius: "45px",
															textDecorationLine: !isAvailable ? "line-through" : "none",
														}}>
														{day}
													</Typography>
													{daysToNotify?.length > 0 && haveNotificationTo(day) && (
														<div
															style={{
																backgroundColor: "#3689ea",
																width: "10px",
																height: "10px",
																borderRadius: "30px",
																position: "relative",
																top: "70px",
																right: "76px",
															}}></div>
													)}
												</>
											);
										})}
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Calendar;
