/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MuiStylesIndicationsPage from "~/components/indicationsPage/MuiStylesIndicationsPage";
import { useSelector } from "react-redux";
import { Button, Typography, Box, List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import TitleContainer from "~/components/common/titleContainer/TitleContainer";
import Footer from "~/components/common/footerContainer/FooterContainer";
import { withRouter } from "react-router-dom";
import { Gift } from "~/components/common/icons/Icons";
import { getGeneratedHash } from "~/services/referearnService";
import { mixpanelTrackEvent } from "~/utils/mixpanel";

const Indications = (props) => {
	const [generatedHashURL, setGeneratedHashURL] = useState("");
	const [generatedHash, setGeneratedHash] = useState("");
	const [cashbackValue, setCashBackValue] = useState(0);
	const [loading, setLoading] = useState(false);
	const classes = MuiStylesIndicationsPage();
	const { me } = useSelector((store) => store.me);
	const { user } = useSelector((store) => store.auth);

	const copyReferralLink = () => {
		const tempInput = document.createElement("input");
		tempInput.value = generatedHashURL;
		document.body.appendChild(tempInput);

		tempInput.select();
		tempInput.setSelectionRange(0, 99999);

		document.execCommand("copy");

		document.body.removeChild(tempInput);
	};

	const handleEarningsClick = () => {
		props.history.push("/indicacoes/seus-ganhos", { hash: generatedHash });
	};
	const handleReferralsClick = () => {
		props.history.push("/indicacoes/suas-indicacoes");
	};
	const handleHowIndicationsWorkClick = () => {
		props.history.push("/indicacoes/como-funciona");
	};

	useEffect(() => {
		const fetchGeneratedHash = async () => {
			try {
				setLoading(true);
				const userId = localStorage.getItem("@prit-web/userId");

				const response = await getGeneratedHash(userId);
				setGeneratedHashURL(response.data.url_hash);
				setGeneratedHash(response.data.hash);
				setCashBackValue(response.data.cashback_value);

				setLoading(false);
			} catch (error) {
				setLoading(false);
				localStorage.setItem("isCommingFromIndications", "true");

				console.error("Erro ao buscar o hash gerado", error);
				props.history.push("/login");
			}
		};

		fetchGeneratedHash();
	}, []);

	const footer = () => {
		return <Footer section="indicacoes" />;
	};

	return (
		<div className={classes.background}>
			<TitleContainer loading={loading} headerStyle={{ boxShadow: "0", padding: "0" }} footer={footer()}>
				<Box
					className={classes.mainGrid}
					style={{
						flexGrow: 1,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						marginTop: "0rem",
					}}>
					<Gift className="gift-icon" />

					<Typography className={classes.title} gutterBottom align="left">
						Ganhe R${cashbackValue || 0} por cada novo assinante que indicar
					</Typography>
					<Typography variant="body1" gutterBottom align="left" className={classes.subtitle}>
						Convide uma pessoa para baixar o Prit e ganhe R${cashbackValue || 0} para cada nova assinatura vinda da sua
						indicação.
					</Typography>
					<List component="nav" aria-label="secondary mailbox folders">
						<ListItem button style={{ padding: "8px 0" }} className={classes.buttonList} onClick={handleReferralsClick}>
							<ListItemText primary="Suas indicações" />
							<ListItemIcon className={classes.iconList}>
								<ArrowForwardIosIcon />
							</ListItemIcon>
						</ListItem>
						<ListItem button className={classes.buttonList} onClick={handleEarningsClick}>
							<ListItemText primary="Seus ganhos" />
							<ListItemIcon className={classes.iconList}>
								<ArrowForwardIosIcon />
							</ListItemIcon>
						</ListItem>
						<ListItem button className={classes.buttonList} onClick={handleHowIndicationsWorkClick}>
							<ListItemText primary="Como funcionam as indicações" />
							<ListItemIcon className={classes.iconList}>
								<ArrowForwardIosIcon />
							</ListItemIcon>
						</ListItem>
					</List>
				</Box>
				{generatedHashURL !== "" && (
					<Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginTop: "0.5rem" }}>
						<Button
							style={{ width: "100%" }}
							variant="contained"
							onClick={() => {
								copyReferralLink();
								mixpanelTrackEvent('Copiou o link Indique e Ganhe', me || user, {});
							}}
							className={classes.copyLinkButton}>
							Copiar Link
						</Button>
					</Box>
				)}
			</TitleContainer>
			{/* <WhatsAppButton bottom={"80px"} /> */}
		</div>
	);
};

export default withRouter(Indications);
