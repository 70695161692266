import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import TitleContainer from "~/components/common/titleContainer/TitleContainer";
import CardBusiness from "~/components/bookingScreen/CardBusiness";
import CardReservations from "~/components/bookingScreen/CardReservations";
import Footer from "~/components/common/footerContainer/FooterContainer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SpinnerLoading from "~/components/common/spinnerLoading/SpinnerLoading";
import { useTranslation } from "react-i18next";
import businessAction from "~/store/actions/businessAction";

const BookingScreen = (props) => {
	const { bookingTransactionId } = useParams();
	const { id } = useParams();
	const { i18n, t } = useTranslation();
	const { business } = useSelector((store) => store.business);
	const { user } = useSelector((store) => store.auth);

	useEffect(() => {
		if (business) {
			if (business?.language === "portuguese" || business?.language === "portugal") {
				i18n.changeLanguage("ptBR");
			} else if (business?.language === "spanish") {
				i18n.changeLanguage("esES");
			} else {
				i18n.changeLanguage("en");
			}
		}
	}, [business, i18n]);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (business) {
			if (business?.language === "portuguese" || business?.language === "portugal") {
				i18n.changeLanguage("ptBR");
			} else if (business?.language === "spanish") {
				i18n.changeLanguage("esES");
			} else {
				i18n.changeLanguage("en");
			}
			if (user && (user.isAnonymous || !user.uid)) {
				localStorage.setItem("goBackRoute", "/reservas/" + business.id + "?bookingTransactionId=" + bookingTransactionId);
				props.history.push(`/login`, {
					goBackRoute: "/reservas/" + business.id + "?bookingTransactionId=" + bookingTransactionId,
					goBackRouteProps: props.location.state,
				});
			}
		} else {
			dispatch(businessAction.getBusiness(id));
		}
		setLoading(false);
	}, [business]);

	useEffect(() => {
		localStorage.setItem("goBackRoute", window.location.pathname);
	}, []);

	return (
		<TitleContainer title={t("My reservations")} subtitle={<CardBusiness />} footer={<Footer section="reservas" />}>
			<SpinnerLoading loading={loading} />
			<CardReservations />
		</TitleContainer>
	);
};
export default withRouter(BookingScreen);
